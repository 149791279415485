import React, { useState, useEffect } from "react";
import {
  ref,
  onValue,
  update,
  off,
  remove,
  runTransaction,
} from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import Board from "./Board";
import CreateBoard from "./CreateBoard";
import {
  List,
  Divider,
  Box,
  Typography,
  IconButton,
  Badge,
  Modal,
  CircularProgress,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChevronLeft, ChevronRight, GroupAdd } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import HomePage from "./HomePage";
import "./Boards.css";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  SquaredButton,
  SquaredButtonGreen,
} from "../../MUI_Components/Components";
import { formatDistanceToNow } from "date-fns";
import { convertToArray } from "../../Helper/utils";
import CelebrationIcon from "@mui/icons-material/Celebration";
import {
  BrowserRouter as Router,
  useNavigate,
  useParams,
} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import boardsIcon from "../../Icons/faviconBoards.ico";
import boardsIconNotification from "../../Icons/faviconBoardsN.ico";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useSettings } from "../../Contexts/SettingsContext";
import RemoveIcon from "@mui/icons-material/Remove";

const Boards = () => {
  const [ownBoards, setOwnBoards] = useState({});
  const [collaboratedBoards, setCollaboratedBoards] = useState({});
  const [pendingInvites, setPendingInvites] = useState([]);
  const [acceptedInvites, setAcceptedInvites] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [boardOwner, setBoardOwner] = useState(null);
  const [loadingInvite, setLoadingInvite] = useState({}); // To track loading state for invites
  const { currentUser } = useAuth();
  const functions = getFunctions();
  const db = getDB();
  const navigate = useNavigate();
  const { boardId } = useParams();
  const [hasNotifications, setHasNotifications] = useState(false);
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const [unacknowledgedChangelog, setUnacknowledgedChangelog] = useState(null);
  const [changelogModalOpen, setChangelogModalOpen] = useState(false);
  const [hovered, setHovered] = useState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { settings } = useSettings();
  const [showArchivedBoards, setShowArchivedBoards] = useState(false);
  const [archivedBoards, setArchivedBoards] = useState({});
  useEffect(() => {
    if (isSmallScreen) {
      setIsSidebarOpen(false);
    }
  }, [isSmallScreen]);

  const handleUnarchiveBoard = (boardId, e) => {
    e.preventDefault();
    e.stopPropagation();

    const board = archivedBoards[boardId];
    const ownerUid = board.owner || currentUser.uid;

    update(ref(db, `/uid/${ownerUid}/boards/${boardId}`), {
      archived: false,
    })
      .then(() => {
        console.log(`Board ${boardId} unarchived`);
      })
      .catch((error) => {
        console.error("Error unarchiving board:", error);
      });
  };

  const checkForNotifications = (boards) => {
    return Object.keys(boards).some((boardId) =>
      convertToArray(boards[boardId].activity)?.some(
        (incident) =>
          incident.acknowledged === false &&
          incident.mentionedUser === currentUser.email
      )
    );
  };

  useEffect(() => {
    function changeIcon() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = hasNotifications ? boardsIconNotification : boardsIcon;
    }
    changeIcon();
  }, [hasNotifications]);

  useEffect(() => {
    if (currentUser) {
      const ownBoardsRef = ref(db, `/uid/${currentUser.uid}/boards`);
      const invitesRef = ref(db, `/invites/${currentUser.uid}`);

      const unsubscribeOwnBoards = onValue(ownBoardsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const active = {};
          const archived = {};

          // Sort boards into active and archived
          Object.entries(data).forEach(([boardId, board]) => {
            if (board.archived === true) {
              archived[boardId] = board;
            } else {
              active[boardId] = board;
            }
          });

          setOwnBoards(active);
          setArchivedBoards(archived);
        } else {
          setOwnBoards({});
          setArchivedBoards({});
        }
      });

      const unsubscribeInvites = onValue(invitesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const pending = [];
          const accepted = [];
          Object.keys(data).forEach((boardId) => {
            const invite = data[boardId];
            if (invite.status === "pending") {
              pending.push({ ...invite, boardId });
            }
            if (invite.status === "accepted") {
              accepted.push({ ...invite, boardId });
            }
          });
          setPendingInvites(pending);
          setAcceptedInvites(accepted);
        } else {
          setPendingInvites([]);
          setAcceptedInvites([]);
        }
      });

      return () => {
        unsubscribeOwnBoards();
        unsubscribeInvites();
      };
    }
  }, [currentUser, db]);

  // For collaborative boards
  useEffect(() => {
    if (acceptedInvites.length === 0) {
      return;
    }
    const collabBoardsListeners = acceptedInvites.map((invite) => {
      const collabBoardRef = ref(
        db,
        `/uid/${invite.senderUid}/boards/${invite.boardId}`
      );
      const unsubscribeCollabBoard = onValue(collabBoardRef, (snapshot) => {
        const data = snapshot.val();

        if (data) {
          // If the board is archived, don't add it to any state for collaborators
          if (data.archived === true) {
            // Remove from collaborated boards if it exists there
            setCollaboratedBoards((prevBoards) => {
              const newBoards = { ...prevBoards };
              delete newBoards[invite.boardId];
              return newBoards;
            });
          } else {
            // Only add non-archived boards to the collaboratedBoards state
            setCollaboratedBoards((prevBoards) => ({
              ...prevBoards,
              [invite.boardId]: { ...data, owner: invite.senderUid },
            }));
          }
        } else {
          // Board was deleted
          setCollaboratedBoards((prevBoards) => {
            const newBoards = { ...prevBoards };
            delete newBoards[invite.boardId];
            return newBoards;
          });

          setArchivedBoards((prevBoards) => {
            const newBoards = { ...prevBoards };
            delete newBoards[invite.boardId];
            return newBoards;
          });

          if (selectedBoardId === invite.boardId) {
            setSelectedBoardId(null);
          }
        }
      });

      return () => {
        off(collabBoardRef, unsubscribeCollabBoard);
      };
    });

    return () => {
      collabBoardsListeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [acceptedInvites, db, selectedBoardId]);

  useEffect(() => {
    if (currentUser) {
      const invitesRef = ref(db, `/invites/${currentUser.uid}`);
      const handleInviteChange = (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const accepted = [];
          Object.keys(data).forEach((boardId) => {
            const invite = data[boardId];
            if (invite.status === "accepted") {
              accepted.push({ ...invite, boardId });
            }
          });
          setAcceptedInvites(accepted);
        } else {
          setAcceptedInvites([]);
          setCollaboratedBoards({});
        }
      };

      onValue(invitesRef, handleInviteChange);

      return () => {
        off(invitesRef, "value", handleInviteChange);
      };
    }
  }, [currentUser, db]);

  useEffect(() => {
    setHasNotifications(
      checkForNotifications(ownBoards) ||
        checkForNotifications(collaboratedBoards)
    );
  }, [ownBoards, collaboratedBoards]);

  const handleBoardClick = (boardId) => {
    setBoardOwner("own");
    setSelectedBoardId(boardId);
    navigate(`/boards/${boardId}`);
  };

  const handleCollaborationBoardClick = (boardId, boardOwnerUID) => {
    setBoardOwner(boardOwnerUID);
    setSelectedBoardId(boardId);
    navigate(`/boards/${boardId}`);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleInviteClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function handleOpenNotifications() {
    setNotificationDrawerOpen(true);
  }

  const handlePinBoard = (boardID) => {
    const userSettingsPath = `/uid/${currentUser.uid}/settings`;
    const pinnedBoardsRef = ref(db, `${userSettingsPath}/pinnedBoards`);

    // Run a transaction to safely add the boardID without duplicates
    runTransaction(pinnedBoardsRef, (currentData) => {
      if (currentData) {
        // Check if the boardID already exists in the pinnedBoards array
        if (!currentData.includes(boardID)) {
          // Add the boardID to the array
          return [...currentData, boardID];
        } else {
          // BoardID is already pinned, return the unchanged array
          return currentData;
        }
      } else {
        // If no data exists, start with an array containing the boardID
        return [boardID];
      }
    });
  };

  const handleUnpinBoard = (boardID) => {
    const userSettingsPath = `/uid/${currentUser.uid}/settings`;
    const pinnedBoardsRef = ref(db, `${userSettingsPath}/pinnedBoards`);

    // Run a transaction to safely remove the boardID if it exists
    runTransaction(pinnedBoardsRef, (currentData) => {
      if (currentData) {
        // Check if the boardID exists in the pinnedBoards array
        if (currentData.includes(boardID)) {
          // Remove the boardID from the array
          return currentData.filter((id) => id !== boardID);
        } else {
          // If the boardID doesn't exist, return the unchanged array
          return currentData;
        }
      } else {
        // If no data exists, just return the same empty array
        return currentData;
      }
    });
  };

  const handleAcceptInvite = async (invite) => {
    setLoadingInvite((prev) => ({ ...prev, [invite.boardId]: true }));
    const acceptInvite = httpsCallable(functions, "acceptInvite");
    try {
      await acceptInvite({
        boardId: invite.boardId,
        userUid: currentUser.uid,
        ownerUID: invite.senderUid,
      });
      setPendingInvites((prev) =>
        prev.filter((i) => i.boardId !== invite.boardId)
      );
    } catch (error) {
      console.error("Error accepting invite:", error);
    } finally {
      setLoadingInvite((prev) => ({ ...prev, [invite.boardId]: false }));
    }
  };

  const handleDeclineInvite = async (invite) => {
    setLoadingInvite((prev) => ({ ...prev, [invite.boardId]: true }));
    const declineInvite = httpsCallable(functions, "declineInvite");
    try {
      await declineInvite({
        boardId: invite.boardId,
        userUid: currentUser.uid,
      });
      setPendingInvites((prev) =>
        prev.filter((i) => i.boardId !== invite.boardId)
      );
    } catch (error) {
      console.error("Error declining invite:", error);
    } finally {
      setLoadingInvite((prev) => ({ ...prev, [invite.boardId]: false }));
    }
  };

  const fetchBoardTitle = (boardOwnerUid, boardId) => {
    const boardPath = `/uid/${boardOwnerUid}/boards/${boardId}`;
    const boardRef = ref(db, boardPath);
    onValue(boardRef, (snapshot) => {
      const boardData = snapshot.val();
      if (boardData) {
        setCollaboratedBoards((prevBoards) => ({
          ...prevBoards,
          [boardId]: { ...prevBoards[boardId], title: boardData.title },
        }));
      }
    });
  };

  useEffect(() => {
    acceptedInvites.forEach((invite) => {
      fetchBoardTitle(invite.senderUid, invite.boardId);
    });
  }, [acceptedInvites]);

  useEffect(() => {
    if (boardId) {
      if (ownBoards[boardId]) {
        setSelectedBoardId(boardId);
        setBoardOwner("own");
      } else if (archivedBoards[boardId]) {
        // This was missing - add handling for archived boards
        setSelectedBoardId(boardId);
        setBoardOwner("own");
      } else if (collaboratedBoards[boardId]) {
        setSelectedBoardId(boardId);
        setBoardOwner(collaboratedBoards[boardId].owner);
      } else {
        setSelectedBoardId(null);
      }
    }
  }, [boardId, ownBoards, archivedBoards, collaboratedBoards]);
  useEffect(() => {
    const db = getDB();
    const changelogRef = ref(db, "boardsChangelog");

    onValue(changelogRef, (snapshot) => {
      const changelogs = [];
      snapshot.forEach((childSnapshot) => {
        changelogs.unshift({ id: childSnapshot.key, ...childSnapshot.val() });
      });

      const unacknowledged = changelogs.find(
        (entry) => entry.users[currentUser.uid]
      );
      if (unacknowledged) {
        setUnacknowledgedChangelog(unacknowledged);
        setChangelogModalOpen(true);
      }
    });
  }, [currentUser]);

  const handleCloseChangelogModal = async () => {
    setChangelogModalOpen(false);
    const db = getDB();
    const changelogRef = ref(
      db,
      `boardsChangelog/${unacknowledgedChangelog?.id}/users/${currentUser.uid}`
    );
    try {
      if (unacknowledgedChangelog?.id) {
        await remove(changelogRef); // Use remove to delete the user's acknowledgment status
      }
      navigate(0);
    } catch (error) {
      console.error("Error acknowledging changelog:", error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "start",
        display: "flex",
        position: "relative",
        backgroundImage: 'url("/assets/background.svg")',
        minHeight: "calc(100dvh - 50px)", // Use viewport height instead of percentage
        backgroundSize: "cover", // Ensure background covers the entire element
        backgroundRepeat: "no-repeat", // Prevent background from repeating
        backgroundPosition: "center", // Center the background
      }}
    >
      <div
        style={{
          width: isSidebarOpen ? 240 : 45,
          flexShrink: 0,
          height: "100%",
          background: "rgba(36, 34, 34, 0.5)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(5px)",
          color: "white",
          transition: "width 0.3s",
          overflowX: "hidden",
          position: "absolute",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            marginLeft: isSidebarOpen ? "10px" : "auto",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        >
          {isSidebarOpen && <Typography variant="h5">Teldio Boards</Typography>}
          <IconButton onClick={toggleSidebar} color="inherit">
            {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        {isSidebarOpen && (
          <>
            <Divider />
            <div
              className="board-item"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={() => {
                navigate(`/boards`);
                setBoardOwner(null);
                setSelectedBoardId(null);
              }}
            >
              <Typography variant="h6">Home</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <HomeIcon />
              </Box>
            </div>
            {settings?.pinnedBoards?.length > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <Typography variant="h6">Pinned</Typography>
                </div>

                {/* Combined pinned boards */}
                <List>
                  {Object.keys({ ...ownBoards, ...collaboratedBoards }).length >
                  0 ? (
                    Object.keys({ ...ownBoards, ...collaboratedBoards })
                      .filter(
                        (boardId) =>
                          settings?.pinnedBoards.includes(boardId) &&
                          !archivedBoards[boardId] // Add this check to exclude archived boards
                      )
                      .map((boardId) => {
                        const board =
                          ownBoards[boardId] || collaboratedBoards[boardId];
                        const isOwnBoard = !!ownBoards[boardId];

                        return (
                          <div
                            key={boardId}
                            onClick={() =>
                              isOwnBoard
                                ? handleBoardClick(boardId)
                                : handleCollaborationBoardClick(
                                    boardId,
                                    board.owner
                                  )
                            }
                            onMouseEnter={() => setHovered(boardId)}
                            onMouseLeave={() => setHovered(null)}
                          >
                            <div
                              className="board-item"
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                justifyContent: "space-between",
                                paddingLeft: "20px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                background:
                                  selectedBoardId === boardId &&
                                  "rgba(184, 181, 181, 0.4)",
                                color: "white",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {!board.background?.startsWith("http") && (
                                  <div
                                    style={{
                                      width: "40px",
                                      height: "20px",
                                      marginRight: "10px",
                                      backgroundColor:
                                        board.background || "grey",
                                      borderRadius: "2px",
                                    }}
                                  ></div>
                                )}
                                {board.background?.startsWith("http") && (
                                  <div
                                    style={{
                                      width: "40px",
                                      height: "20px",
                                      marginRight: "10px",
                                      backgroundImage: `url(${board.background})`,
                                      borderRadius: "2px",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                )}
                                <Typography variant="subtitle1">
                                  {board.title || "Untitled"}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "right",
                                  alignItems: "center",
                                  position: "absolute",
                                  right: 0,
                                }}
                              >
                                {hovered === boardId && (
                                  <IconButton
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleUnpinBoard(boardId);
                                    }}
                                  >
                                    <RemoveIcon
                                      sx={{ fontSize: 18, color: "black" }}
                                    />
                                  </IconButton>
                                )}

                                {convertToArray(board.activity)?.filter(
                                  (incident) =>
                                    incident.acknowledged === false &&
                                    incident.mentionedUser === currentUser.email
                                ).length > 0 && (
                                  <Badge
                                    badgeContent={
                                      convertToArray(board.activity)?.filter(
                                        (incident) =>
                                          incident.acknowledged === false &&
                                          incident.mentionedUser ===
                                            currentUser.email
                                      ).length
                                    }
                                    color="error"
                                    sx={{ marginRight: "15px" }}
                                  >
                                    <CelebrationIcon
                                      onClick={() => {
                                        isOwnBoard
                                          ? handleBoardClick(boardId)
                                          : handleCollaborationBoardClick(
                                              boardId,
                                              board.owner
                                            );
                                        handleOpenNotifications();
                                      }}
                                    />
                                  </Badge>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <Typography variant="h6" style={{ padding: "20px" }}>
                      No pinned boards available
                    </Typography>
                  )}
                </List>
              </>
            )}
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <Typography variant="h6">My boards</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CreateBoard />
              </Box>
            </div>
            <List>
              {Object.keys(ownBoards).length > 0 ? (
                Object.keys(ownBoards)
                  .filter((board) => !settings?.pinnedBoards?.includes(board))
                  .map((boardId) => (
                    <div
                      key={boardId}
                      onClick={() => handleBoardClick(boardId)}
                      onMouseEnter={(e) => {
                        setHovered(boardId);
                      }}
                      onMouseLeave={(e) => {
                        setHovered(null);
                      }}
                    >
                      <div
                        onClick={() => handleBoardClick(boardId)}
                        className="board-item"
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          justifyContent: "space-between",
                          paddingLeft: "20px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          background:
                            selectedBoardId === boardId &&
                            "rgba(184, 181, 181, 0.4)",
                          color: "white",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {!ownBoards[boardId]?.background?.startsWith(
                            "http"
                          ) && (
                            <div
                              style={{
                                width: "40px",
                                height: "20px",
                                marginRight: "10px",
                                backgroundColor:
                                  ownBoards[boardId]?.background || "grey",
                                borderRadius: "2px",
                              }}
                            ></div>
                          )}
                          {ownBoards[boardId]?.background?.startsWith(
                            "http"
                          ) && (
                            <div
                              style={{
                                width: "40px",
                                height: "20px",
                                marginRight: "10px",
                                backgroundImage: ownBoards[
                                  boardId
                                ]?.background?.startsWith("http")
                                  ? `url(${ownBoards[boardId]?.background})`
                                  : "none",
                                borderRadius: "2px",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          )}
                          <Typography variant="subtitle1">
                            {ownBoards[boardId]?.title || "Untitled"}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "center",
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          {hovered === boardId && (
                            <IconButton
                              size="small"
                              sx={{ mr: 1 }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handlePinBoard(boardId);
                              }}
                            >
                              <PushPinIcon
                                sx={{ fontSize: 18, color: "black" }}
                              />
                            </IconButton>
                          )}

                          {convertToArray(ownBoards[boardId].activity)?.filter(
                            (incident) =>
                              incident.acknowledged === false &&
                              incident.mentionedUser === currentUser.email
                          ).length > 0 && (
                            <Badge
                              badgeContent={
                                convertToArray(
                                  ownBoards[boardId].activity
                                )?.filter(
                                  (incident) =>
                                    incident.acknowledged === false &&
                                    incident.mentionedUser === currentUser.email
                                ).length
                              }
                              color="error"
                              sx={{ marginRight: "15px" }}
                            >
                              <CelebrationIcon
                                onClick={() => {
                                  handleBoardClick(boardId);
                                  handleOpenNotifications();
                                }}
                              />
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <Typography variant="h6" style={{ padding: "20px" }}>
                  No boards available
                </Typography>
              )}
            </List>
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <Typography variant="h6">Collaborations</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Badge badgeContent={pendingInvites.length} color="error">
                  <IconButton onClick={handleInviteClick} color="inherit">
                    <GroupAdd />
                  </IconButton>
                </Badge>
              </Box>
            </div>
            <List>
              {Object.keys(collaboratedBoards).length > 0 ? (
                Object.keys(collaboratedBoards)
                  .filter((board) => !settings?.pinnedBoards?.includes(board))
                  .map((boardId) => (
                    <div
                      key={boardId}
                      onClick={() =>
                        handleCollaborationBoardClick(
                          boardId,
                          collaboratedBoards[boardId]?.owner
                        )
                      }
                      onMouseEnter={(e) => {
                        setHovered(boardId);
                      }}
                      onMouseLeave={(e) => {
                        setHovered(null);
                      }}
                    >
                      <div
                        onClick={() =>
                          handleCollaborationBoardClick(
                            boardId,
                            collaboratedBoards[boardId]?.owner
                          )
                        }
                        className="board-item"
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          justifyContent: "space-between",
                          paddingLeft: "20px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          background:
                            selectedBoardId === boardId &&
                            "rgba(184, 181, 181, 0.4)",
                          color: "white",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {!collaboratedBoards[boardId]?.background?.startsWith(
                            "http"
                          ) && (
                            <div
                              style={{
                                width: "40px",
                                height: "20px",
                                marginRight: "10px",
                                backgroundColor:
                                  collaboratedBoards[boardId]?.background ||
                                  "grey",
                                borderRadius: "2px",
                              }}
                            ></div>
                          )}
                          {collaboratedBoards[boardId]?.background?.startsWith(
                            "http"
                          ) && (
                            <div
                              style={{
                                width: "40px",
                                height: "20px",
                                marginRight: "10px",
                                backgroundImage: `url(${collaboratedBoards[boardId]?.background})`,
                                borderRadius: "2px",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          )}

                          <Typography variant="subtitle1">
                            {collaboratedBoards[boardId]?.title || "Untitled"}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "center",
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          {hovered === boardId && (
                            <IconButton
                              size="small"
                              sx={{ mr: 1 }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handlePinBoard(boardId);
                              }}
                            >
                              <PushPinIcon
                                sx={{ fontSize: 18, color: "black" }}
                              />
                            </IconButton>
                          )}
                          {convertToArray(
                            collaboratedBoards[boardId].activity
                          )?.filter(
                            (incident) =>
                              incident.acknowledged === false &&
                              incident.mentionedUser === currentUser.email
                          ).length > 0 && (
                            <Badge
                              badgeContent={
                                convertToArray(
                                  collaboratedBoards[boardId].activity
                                )?.filter(
                                  (incident) =>
                                    incident.acknowledged === false &&
                                    incident.mentionedUser === currentUser.email
                                ).length
                              }
                              color="error"
                              sx={{ marginRight: "15px" }}
                            >
                              <CelebrationIcon
                                onClick={() => {
                                  handleCollaborationBoardClick(
                                    boardId,
                                    collaboratedBoards[boardId]?.owner
                                  );
                                  handleOpenNotifications();
                                }}
                              />
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <Typography
                  variant="subtitle1"
                  style={{ padding: "0px 20px 20px 20px" }}
                >
                  No collaborations available
                </Typography>
              )}
            </List>
          </>
        )}
        {isSidebarOpen && (
          <div style={{ marginBottom: "20px" }}>
            <Divider sx={{ mt: 2 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginLeft: "10px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowArchivedBoards(!showArchivedBoards)}
            >
              <Typography variant="h6">Archived Boards</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                {showArchivedBoards ? <ChevronLeft /> : <ChevronRight />}
              </Box>
            </div>

            {showArchivedBoards && (
              <List>
                {Object.keys(archivedBoards).length > 0 ? (
                  Object.keys(archivedBoards).map((boardId) => (
                    <div
                      key={boardId}
                      onMouseEnter={() => setHovered(boardId)}
                      onMouseLeave={() => setHovered(null)}
                    >
                      <div
                        className="board-item"
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          justifyContent: "space-between",
                          paddingLeft: "20px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          color: "white",
                          alignItems: "center",
                          background:
                            selectedBoardId === boardId &&
                            "rgba(184, 181, 181, 0.4)",
                        }}
                        onClick={() => {
                          const board = archivedBoards[boardId];
                          const isCollaborativeBoard = !!board.owner;

                          if (isCollaborativeBoard) {
                            handleCollaborationBoardClick(boardId, board.owner);
                          } else {
                            handleBoardClick(boardId);
                          }
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {!archivedBoards[boardId]?.background?.startsWith(
                            "http"
                          ) && (
                            <div
                              style={{
                                width: "40px",
                                height: "20px",
                                marginRight: "10px",
                                backgroundColor:
                                  archivedBoards[boardId]?.background || "grey",
                                borderRadius: "2px",
                                opacity: 0.7, // Slightly faded look for archived boards
                              }}
                            ></div>
                          )}
                          {archivedBoards[boardId]?.background?.startsWith(
                            "http"
                          ) && (
                            <div
                              style={{
                                width: "40px",
                                height: "20px",
                                marginRight: "10px",
                                backgroundImage: `url(${archivedBoards[boardId]?.background})`,
                                borderRadius: "2px",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                opacity: 0.7, // Slightly faded look for archived boards
                              }}
                            ></div>
                          )}
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontStyle: "italic", // Italicize archived board names
                              opacity: 0.9,
                            }}
                          >
                            {archivedBoards[boardId]?.title || "Untitled"}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "center",
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          {hovered === boardId && (
                            <Button
                              size="small"
                              sx={{
                                mr: 1,
                                fontSize: "0.7rem",
                              }}
                              variant="contained"
                              onClick={(e) => handleUnarchiveBoard(boardId, e)}
                            >
                              Restore
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{ padding: "10px 20px" }}
                  >
                    No archived boards
                  </Typography>
                )}
              </List>
            )}
          </div>
        )}
      </div>
      <Box
        sx={{
          width: "100%",
          transition: "width 0.3s",
        }}
      >
        {!selectedBoardId && (
          <HomePage
            isSidebarOpen={isSidebarOpen}
            ownBoards={ownBoards}
            collaboratedBoards={collaboratedBoards}
            handleCollaborationBoardClick={handleCollaborationBoardClick}
            handleBoardClick={handleBoardClick}
          />
        )}
        {selectedBoardId && (
          <div>
            <Board
              boardId={selectedBoardId}
              isSidebarOpen={isSidebarOpen}
              boardOwner={boardOwner}
              notificationDrawerOpen={notificationDrawerOpen}
              setNotificationDrawerOpen={setNotificationDrawerOpen}
              ownBoards={ownBoards}
              collaboratedBoards={collaboratedBoards}
            />
          </div>
        )}
      </Box>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Pending Invites
          </Typography>
          <Divider sx={{ my: 2 }} />
          {pendingInvites.length > 0 ? (
            pendingInvites.map((invite) => (
              <Box
                key={invite.boardId}
                sx={{
                  mb: 2,
                  p: 2,
                  bgcolor: "grey.100",
                  borderRadius: 1,
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {invite.senderEmail} has invited you to join board{" "}
                  <strong>{invite.boardName}</strong>
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {formatDistanceToNow(new Date(invite.timestamp), {
                    addSuffix: true,
                  })}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 1,
                  }}
                >
                  <SquaredButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeclineInvite(invite)}
                    sx={{ marginRight: "20px" }}
                    disabled={loadingInvite[invite.boardId]}
                  >
                    {loadingInvite[invite.boardId] ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Decline"
                    )}
                  </SquaredButton>
                  <SquaredButtonGreen
                    variant="contained"
                    onClick={() => handleAcceptInvite(invite)}
                    disabled={loadingInvite[invite.boardId]}
                  >
                    {loadingInvite[invite.boardId] ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Accept"
                    )}
                  </SquaredButtonGreen>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1">No pending invites</Typography>
          )}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>

      <Modal
        open={changelogModalOpen}
        onClose={handleCloseChangelogModal}
        aria-labelledby="changelog-modal-title"
        aria-describedby="changelog-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="changelog-modal-title" variant="h5" component="h2">
            New features inbound!
          </Typography>
          <Typography
            id="changelog-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            {unacknowledgedChangelog?.title}
          </Typography>
          <Typography
            id="changelog-modal-description"
            sx={{ mt: 2, whiteSpace: "pre-wrap" }}
          >
            {unacknowledgedChangelog?.text}
          </Typography>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <SquaredButtonGreen
              onClick={handleCloseChangelogModal}
              sx={{ mt: 2 }}
              variant="contained"
            >
              Got it!
            </SquaredButtonGreen>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Boards;
