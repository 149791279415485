import React, { useEffect, useState, useRef } from "react";
import { useEditor, EditorContent, Extension } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import { CodeBlockLowlight } from "@tiptap/extension-code-block-lowlight";
import { createLowlight } from "lowlight";
import Placeholder from "@tiptap/extension-placeholder";
import ImageResize from "tiptap-extension-resize-image";
import Image from "@tiptap/extension-image";
import js from "highlight.js/lib/languages/javascript";
import "highlight.js/styles/github.css";
import Dropcursor from "@tiptap/extension-dropcursor";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FontFamily from "@tiptap/extension-font-family";
import debounce from "lodash.debounce";
import heic2any from "heic2any";
import { v4 as uuidv4 } from "uuid";
import Link from "@tiptap/extension-link";

import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { getDatabase, ref, update, get } from "firebase/database";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Tooltip,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatListBulleted,
  FormatListNumbered,
  Code,
  HorizontalRule as DividerIcon,
} from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import NotesIcon from "@mui/icons-material/Notes";
import { useSettings } from "../../../Contexts/SettingsContext";
import {
  SquaredButtonGray,
  SquaredButtonGreen,
  TeldioLinearProgress,
  TeldioOutlinedInputBase,
  TeldioSelectBase,
  TeldioTextField,
} from "../../../MUI_Components/Components";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import "./RichDescription.css";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { useThemeContext } from "../../../Contexts/ThemeDetailsContext";

const lowlight = createLowlight();
lowlight.register("javascript", js);

// Define CustomTabExtension without extending StarterKit
const CustomTabExtension = Extension.create({
  addKeyboardShortcuts() {
    return {
      Tab: () => {
        if (this.editor.can().sinkListItem("listItem")) {
          return this.editor.chain().focus().sinkListItem("listItem").run();
        } else {
          return this.editor.chain().focus().insertContent("    ").run();
        }
      },
    };
  },
});

const RichDescription = ({
  taskId,
  boardId,
  columnId,
  taskPath,
  setEditingDescription,
  handleCloseModal,
  logAudit,
  taskName,
  task,
}) => {
  const { settings } = useSettings();
  const [firebaseDescription, setFirebaseDescription] = useState(null);
  const fileInputRef = useRef(null);
  const [fontColor, setFontColor] = useState("#000000");
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [showSaved, setShowSaved] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { currentUser } = useAuth();
  const taskNameRef = useRef(taskName);
  const themeDetails = useThemeContext();

  // Update taskNameRef whenever taskName changes
  useEffect(() => {
    taskNameRef.current = taskName;
  }, [taskName]);

  // Create a ref to store the previous content
  const previousContentRef = useRef(firebaseDescription);

  // Update previousContentRef when firebaseDescription changes
  useEffect(() => {
    if (firebaseDescription !== null) {
      previousContentRef.current = firebaseDescription;
    }
  }, [firebaseDescription]);

  const applyFontColor = debounce((fontColor) => {
    editor.chain().focus().setColor(fontColor).run();
  }, 300);

  // State variables for link dialog
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkText, setLinkText] = useState("");

  const toggleMoreOptions = () => {
    setShowMoreOptions((prev) => !prev);
  };

  const removeAllStyling = () => {
    editor
      .chain()
      .focus()
      .unsetBold()
      .unsetItalic()
      .unsetUnderline()
      .unsetColor()
      .setFontFamily("Arial")
      .run();
  };

  const handleFontChange = (event) => {
    const font = event.target.value;
    setFontFamily(font);
    editor.chain().focus().setFontFamily(font).run();
  };

  useEffect(() => {
    const fetchDescription = async () => {
      const db = getDatabase();
      const descriptionRef = ref(db, `${taskPath}/description`);
      const snapshot = await get(descriptionRef);

      if (snapshot.exists()) {
        let description = snapshot.val();

        try {
          // Check if the description is in draft-js JSON format
          const potentialDraftContent = JSON.parse(description);

          // If it has the structure of a draft-js content state, convert it to HTML
          if (
            potentialDraftContent &&
            potentialDraftContent.blocks &&
            potentialDraftContent.entityMap
          ) {
            // Simple conversion from draft-js format to HTML
            // This is a basic implementation to replace the stateToHTML function
            let html = "";
            potentialDraftContent.blocks.forEach((block) => {
              const blockText = block.text;

              // Handle different block types
              switch (block.type) {
                case "header-one":
                  html += `<h1>${blockText}</h1>`;
                  break;
                case "header-two":
                  html += `<h2>${blockText}</h2>`;
                  break;
                case "unordered-list-item":
                  html += `<ul><li>${blockText}</li></ul>`;
                  break;
                case "ordered-list-item":
                  html += `<ol><li>${blockText}</li></ol>`;
                  break;
                case "blockquote":
                  html += `<blockquote>${blockText}</blockquote>`;
                  break;
                case "code-block":
                  html += `<pre><code>${blockText}</code></pre>`;
                  break;
                default:
                  html += `<p>${blockText}</p>`;
              }
            });

            description = html;
          }
        } catch (error) {
          console.log(
            "Description is already in HTML format or parsing failed:",
            error
          );
        }

        setFirebaseDescription(description);
      }
    };

    fetchDescription();
  }, [taskId, boardId, columnId, taskPath]);

  const manualSave = () => {
    if (editor) {
      const content = editor.getHTML();
      const db = getDatabase();
      const descriptionRef = ref(db, `${taskPath}`);

      update(descriptionRef, { description: content }).then(() => {
        setEditingDescription(false);
        handleCloseModal();
        setTimeout(() => {
          setShowSaved(false);
        }, 1000);
      });
    }
  };

  const uploadImageToFirebase = async (fileToUpload) => {
    const sanitizedFileName = fileToUpload.name.replace(/[.$\/\[\]#]/g, "-");
    const uniqueIdentifier = uuidv4(); // You can also use Date.now() for uniqueness
    const uniqueFileName = `${sanitizedFileName}-${uniqueIdentifier}`;

    const storage = getStorage();
    const storageReference = storageRef(
      storage,
      `images/${taskPath}/${uniqueFileName}`
    );

    try {
      // Upload the file to Firebase Storage
      const snapshot = await uploadBytes(storageReference, fileToUpload);
      // Get the download URL after the upload completes
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL; // Return the URL of the uploaded image
    } catch (error) {
      console.error("Error uploading image to Firebase:", error);
      return null; // Handle any errors during upload
    }
  };

  function logEditedDescription() {
    console.log("taskName", taskName);
    logAudit(
      "Edited Description",
      `${
        currentUser.displayName || currentUser.email
      } edited description on card ${taskName}`
    );
  }

  // Modify debouncedSave to compare contents
  const debouncedSave = useRef(
    debounce((content) => {
      // Compare the new content with the previous content
      if (content !== previousContentRef.current) {
        const db = getDatabase();
        const descriptionRef = ref(db, `${taskPath}`);

        update(descriptionRef, { description: content });

        setShowSaved(true);
        setEditingDescription(false);
        console.log("taskName", taskNameRef.current);
        logAudit(
          "Edited Description",
          `${
            currentUser.displayName || currentUser.email
          } edited description on card ${taskNameRef.current}`,
          `has edited the description`
        );
        setTimeout(() => {
          setShowSaved(false);
        }, 1000);

        // Update the previous content reference
        previousContentRef.current = content;
      }
    }, 2000)
  ).current;

  const OverrideEscape = Extension.create({
    name: "OverrideEscape",
    addKeyboardShortcuts() {
      return {
        escape: () => {
          const isFocused = this.editor.isFocused;
          if (isFocused) {
            this.editor.commands.blur();
            return true; // Prevents further propagation
          }
          return false; // Allows the event to bubble up if the editor is not focused
        },
      };
    },
  });

  // Handle Ctrl/Cmd+Click to open links
  const editorProps = {
    handleClick(view, pos, event) {
      // More general approach to catch all clicks
      const { state } = view;
      const { doc, selection } = state;
      const $pos = doc.resolve(pos);

      // Log position information to debug
      console.log("Click position:", pos);
      console.log("Node at position:", $pos.parent);

      // Try to find any link at the click position
      let foundLink = false;
      state.doc.nodesBetween(pos, pos, (node, nodePos) => {
        // Look through all marks on this node
        if (node.isText) {
          console.log("Text node found:", node.text);
          console.log(
            "Marks:",
            node.marks.map((m) => `${m.type.name}: ${JSON.stringify(m.attrs)}`)
          );

          // Check if any marks are links
          node.marks.forEach((mark) => {
            if (mark.type.name === "link") {
              console.log("Link found:", mark.attrs.href);
              window.open(mark.attrs.href, "_blank");
              foundLink = true;
            }
          });
        }
      });

      return foundLink; // Return true if we handled a link click
    },

    // Keep the original handler as backup
    handleClickOn(view, pos, node, nodePos, event, direct) {
      console.log("handleClickOn called");
      console.log("Node:", node);
      console.log("Node marks:", node.marks);

      // Check if this node or its parent has any link marks
      const attrs = node.marks.find((mark) => mark.type.name === "link")?.attrs;

      if (attrs?.href) {
        console.log("Link found with href:", attrs.href);
        window.open(attrs.href, "_blank");
        return true;
      }

      return false;
    },
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: "Write something …",
      }),
      OverrideEscape,
      CustomTabExtension,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      TextStyle,
      Color,
      Underline,
      HorizontalRule,
      Image.configure({
        inline: true,
        draggable: true,
        HTMLAttributes: {
          style: "max-width: 100%; height: auto;",
        },
      }),
      Dropcursor,
      ImageResize,
      FontFamily,
      Link.configure({
        openOnClick: false, // We'll handle click manually
        linkOnPaste: true,
        autolink: true,
        defaultProtocol: "https",
      }),
    ],
    content: firebaseDescription || "",
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      setEditingDescription(true);
      debouncedSave(content);
    },
    editorProps: {
      ...editorProps,
      attributes: {
        spellcheck: "false",
      },
      handlePaste(view, event) {
        event.stopPropagation();
        const clipboardData = event.clipboardData || window.clipboardData;

        // Check if there's any text to paste
        const textData = clipboardData.getData("text");

        if (textData) {
          // Let TipTap handle text paste
          return false; // Returning false will allow TipTap to process the paste as usual
        }

        // If there's no text, check for image or file
        const items = clipboardData.items;
        let handled = false;

        for (let i = 0; i < items.length; i++) {
          const item = items[i];

          // Handle image paste
          if (item.kind === "file" && item.type.startsWith("image/")) {
            event.preventDefault(); // Prevent default paste behavior for image
            const file = item.getAsFile();
            let fileToUpload = file;

            setUploading(true);

            // Convert HEIC images to JPEG if needed
            if (file.type === "image/heic" || file.name.endsWith(".heic")) {
              heic2any({ blob: file, toType: "image/jpeg" })
                .then((result) => {
                  const jpegBlob = new Blob([result], { type: "image/jpeg" });
                  fileToUpload = new File(
                    [jpegBlob],
                    `${file.name.split(".")[0]}.jpeg`,
                    {
                      type: "image/jpeg",
                    }
                  );

                  return uploadImageToFirebase(fileToUpload);
                })
                .then((url) => {
                  editor.chain().focus().setImage({ src: url }).run();
                  setUploading(false);
                })
                .catch((error) => {
                  console.error("Error converting HEIC to JPEG:", error);
                  setUploading(false);
                });
            } else {
              // Directly upload and add image if not HEIC
              uploadImageToFirebase(fileToUpload).then((url) => {
                editor.chain().focus().setImage({ src: url }).run();
                setUploading(false);
              });
            }

            handled = true;
            break;
          }
        }

        // If no file was handled, return false to allow default text handling
        if (!handled) {
          return false;
        }

        return true; // Custom handling was done (e.g., image pasted)
      },
    },
  });

  const handleHeadingChange = (event) => {
    const value = event.target.value;
    if (value === "paragraph") {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level: value }).run();
    }
  };

  useEffect(() => {
    if (firebaseDescription && editor) {
      editor.commands.setContent(firebaseDescription);
    }
  }, [firebaseDescription, editor]);

  const handleFileUpload = async (file) => {
    const storage = getStorage();
    setUploading(true);

    let fileToUpload = file;

    // Check if the file is a .heic file and convert it before uploading
    if (file.type === "image/heic" || file.name.endsWith(".heic")) {
      try {
        const heicConversionResult = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        const jpegBlob = new Blob([heicConversionResult], {
          type: "image/jpeg",
        });

        // Create a new File object for the converted JPEG file
        fileToUpload = new File([jpegBlob], `${file.name.split(".")[0]}.jpeg`, {
          type: "image/jpeg",
        });
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        alert("Failed to convert HEIC image. Please try another file.");
        return;
      }
    }

    // Sanitize the file name by replacing Firebase disallowed characters
    const sanitizedFileName = fileToUpload.name.replace(/[.$\/\[\]#]/g, "-");

    // Generate a unique identifier (UUID or timestamp) to add to the file name
    const uniqueIdentifier = uuidv4(); // Alternatively, you can use Date.now()
    const uniqueFileName = `${sanitizedFileName}-${uniqueIdentifier}`;

    const storageReference = storageRef(
      storage,
      `images/${taskPath}/${uniqueFileName}`
    );

    // Upload the file (original or converted) to Firebase Storage
    uploadBytes(storageReference, fileToUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        // Use the image URL in the editor
        editor.chain().focus().setImage({ src: url }).run();
        setUploading(false);
      });
    });
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      handleFileUpload(file);
    }
  };

  // Handle opening the link dialog
  const handleOpenLinkDialog = () => {
    const previousUrl = editor.getAttributes("link").href;
    setLinkUrl(previousUrl || "");

    if (editor.state.selection.empty) {
      setLinkText("");
    } else {
      const selectedText = editor.state.doc.textBetween(
        editor.state.selection.from,
        editor.state.selection.to,
        " "
      );
      setLinkText(selectedText);
    }
    setOpenLinkDialog(true);
  };

  // Handle confirming the link
  const handleConfirmLink = () => {
    if (linkUrl === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    } else {
      if (editor.state.selection.empty && linkText) {
        editor
          .chain()
          .focus()
          .insertContent(linkText)
          .extendMarkRange("link")
          .setLink({ href: linkUrl })
          .run();
      } else {
        editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: linkUrl })
          .run();
      }
    }
    setOpenLinkDialog(false);
  };

  // Add CSS to change cursor over links
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .ProseMirror a {
        cursor: pointer;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <NotesIcon />
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Description
        </Typography>
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginBottom: 4,
            alignItems: "center",
          }}
        >
          <TeldioSelectBase
            inputProps={{
              "aria-label": "Without label",
              MenuProps: {
                MenuListProps: {
                  sx: {
                    backgroundColor:
                      themeDetails[settings.cardColor]?.primary || "white",
                    color: themeDetails[settings.cardColor]?.font || "black",
                  },
                },
                PopoverClasses: {
                  paper: {
                    textAlign: "right",
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              },
            }}
            placeholderColor={
              themeDetails[settings.cardColor]?.font || "darkgrey"
            }
            input={<TeldioOutlinedInputBase />}
            value={
              editor.isActive("paragraph")
                ? "paragraph"
                : editor.getAttributes("heading").level || ""
            }
            onChange={handleHeadingChange}
            displayEmpty
            size="small"
            sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
          >
            <MenuItem value={1} sx={{ fontSize: "30px" }}>
              Heading 1
            </MenuItem>
            <MenuItem value={2} sx={{ fontSize: "25px" }}>
              Heading 2
            </MenuItem>
            <MenuItem value="paragraph">Paragraph</MenuItem>
          </TeldioSelectBase>
          <Tooltip title="Bold">
            <IconButton
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
            >
              <FormatBold
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Italic">
            <IconButton
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
            >
              <FormatItalic
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Underline">
            <IconButton
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              disabled={!editor.can().chain().focus().toggleUnderline().run()}
            >
              <FormatUnderlined
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="color"
              value={fontColor}
              onChange={(e) => applyFontColor(e.target.value)}
            />
          </div>
          <Tooltip title="Undo">
            <IconButton onClick={() => editor.chain().focus().undo().run()}>
              <UndoIcon
                sx={{
                  marginLeft: "10px",
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Redo">
            <IconButton onClick={() => editor.chain().focus().redo().run()}>
              <RedoIcon
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={toggleMoreOptions}
            sx={{
              color: themeDetails[settings.cardColor]?.font || "black",
            }}
          >
            {showMoreOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Typography
          sx={{
            fontSize: "15px",
            cursor: "default",
            textDecoration: "underline",
            color: "darkgreen",
            opacity: showSaved ? 0.8 : 0,
            transition: "opacity 1s ease-in-out",
          }}
        >
          Saved!
        </Typography>
      </div>
      {showMoreOptions && (
        <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 4 }}>
          <Tooltip title="Align Left">
            <IconButton
              onClick={() => editor.chain().focus().setTextAlign("left").run()}
            >
              <FormatAlignLeft
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Center">
            <IconButton
              onClick={() =>
                editor.chain().focus().setTextAlign("center").run()
              }
            >
              <FormatAlignCenter
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Right">
            <IconButton
              onClick={() => editor.chain().focus().setTextAlign("right").run()}
            >
              <FormatAlignRight
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Bullet List">
            <IconButton
              onClick={() => editor.chain().focus().toggleBulletList().run()}
            >
              <FormatListBulleted
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ordered List">
            <IconButton
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
            >
              <FormatListNumbered
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Code Block">
            <IconButton
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            >
              <Code
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Divider">
            <IconButton
              onClick={() => editor.chain().focus().setHorizontalRule().run()}
            >
              <DividerIcon
                fontSize="small"
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Clear Formatting">
            <IconButton
              onClick={removeAllStyling}
              aria-label="Clear Formatting"
            >
              <FormatClearIcon
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Upload Image">
            <IconButton
              onClick={handleIconClick}
              aria-label="Upload Image"
              sx={{
                color: themeDetails[settings.cardColor]?.font || "black",
              }}
            >
              <ImageIcon />
            </IconButton>
          </Tooltip>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                handleFileUpload(file);
              }
            }}
          />
          <Tooltip title="Add/Edit Link">
            <IconButton
              onClick={handleOpenLinkDialog}
              aria-label="Add Link"
              sx={{
                color: themeDetails[settings.cardColor]?.font || "black",
              }}
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
          <TeldioSelectBase
            inputProps={{
              "aria-label": "Font Selector",
              MenuProps: {
                MenuListProps: {
                  sx: {
                    backgroundColor:
                      themeDetails[settings.cardColor]?.primary || "white",
                    color: themeDetails[settings.cardColor]?.font || "black",
                  },
                },
                PopoverClasses: {
                  paper: {
                    textAlign: "right",
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              },
            }}
            placeholderColor={
              themeDetails[settings.cardColor]?.font || "darkgrey"
            }
            value={fontFamily}
            onChange={handleFontChange}
            displayEmpty
            size="small"
            input={<TeldioOutlinedInputBase />}
            sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
          >
            <MenuItem value="Arial" sx={{ fontFamily: "Arial" }}>
              Arial
            </MenuItem>
            <MenuItem value="Courier New" sx={{ fontFamily: "Courier New" }}>
              Courier New
            </MenuItem>
            <MenuItem value="Georgia" sx={{ fontFamily: "Georgia" }}>
              Georgia
            </MenuItem>
            <MenuItem value="Tahoma" sx={{ fontFamily: "Tahoma" }}>
              Tahoma
            </MenuItem>
            <MenuItem value="Verdana" sx={{ fontFamily: "Verdana" }}>
              Verdana
            </MenuItem>
          </TeldioSelectBase>
        </div>
      )}

      {uploading && (
        <div style={{ width: "100%", height: "20px" }}>
          <TeldioLinearProgress sx={{ width: "100%" }} />
        </div>
      )}

      <div
        className="editor-container"
        style={{
          backgroundColor:
            themeDetails[settings.cardColor]?.primary || "#F3F4F5",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <EditorContent editor={editor} />
      </div>

      {/* Custom Modal for Adding/Editing Links */}
      <Dialog open={openLinkDialog} onClose={() => setOpenLinkDialog(false)}>
        <DialogTitle>Add/Edit Link</DialogTitle>
        <DialogContent sx={{ width: "400px" }}>
          <TeldioTextField
            autoFocus
            margin="dense"
            label="URL"
            fullWidth
            variant="outlined"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
          {editor.state.selection.empty && (
            <TeldioTextField
              margin="dense"
              label="Display Text"
              fullWidth
              variant="outlined"
              value={linkText}
              onChange={(e) => setLinkText(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <SquaredButtonGray
            onClick={() => setOpenLinkDialog(false)}
            sx={{ margin: 0 }}
          >
            Cancel
          </SquaredButtonGray>
          <SquaredButtonGreen
            onClick={handleConfirmLink}
            sx={{ marginBottom: "10px" }}
          >
            Confirm
          </SquaredButtonGreen>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RichDescription;
