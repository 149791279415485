import React, { useState, useEffect, useCallback } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  Handle,
  Position,
} from "reactflow";
import "reactflow/dist/style.css";

import { Button, Box } from "@mui/material";

/* -----------------------------------------------------
   1) Custom Node-RED node component (to mimic Node-RED style)
----------------------------------------------------- */
function NodeRedNode({ data }) {
  // This function picks a left-border color or style based on node type
  const nodeColor = getColorByType(data.nodeType);

  // If you want multiple outputs, you can pass "outputs" from parseNodeRedFlow
  const { inputs = 1, outputs = 1 } = data;

  return (
    <div
      style={{
        border: "1px solid #888",
        borderLeftWidth: "8px",
        borderLeftColor: nodeColor,
        backgroundColor: "#fafafa",
        fontFamily: "sans-serif",
        padding: "4px 8px",
        borderRadius: "4px",
        minWidth: "120px",
        boxShadow: "2px 2px 5px rgba(0,0,0,0.1)",
        position: "relative",
      }}
    >
      {/* The node title */}
      <div style={{ fontSize: "12px", fontWeight: 600, marginBottom: "4px" }}>
        {data.label || "Node"}
      </div>

      {/* Input handle (left side) – show only if needed */}
      {inputs > 0 && (
        <Handle
          type="target"
          position={Position.Left}
          style={{ top: "50%", background: "#555" }}
        />
      )}

      {/* Output handles (right side) – one for each "output" */}
      {Array.from({ length: outputs }).map((_, index) => (
        <Handle
          key={index}
          type="source"
          position={Position.Right}
          style={{ top: `${50 + index * 10}%`, background: "#555" }}
        />
      ))}
    </div>
  );
}

/* -----------------------------------------------------
   2) Color function for Node-RED types
----------------------------------------------------- */
function getColorByType(type) {
  switch (type) {
    case "debug":
      return "#0094CE";
    case "function":
      return "#a6bbcf";
    case "inject":
      return "#32b5e5";
    case "module-in":
      return "#afa27f";
    case "module-out":
      return "#d8d8d8";
    default:
      return "#999";
  }
}

/* -----------------------------------------------------
   3) Our main NodeRedLibrary component
      - expects a "flow" prop with the Node-RED JSON
----------------------------------------------------- */
export default function NodeRedLibrary() {
  const flow = [
    {
      id: "467355ff.9a3d8c",
      type: "module-in",
      z: "c9826de2.d8985",
      name: "",
      cname: "simulator_event",
      module: "customevent",
      event: "customevent:simulator_event",
      map: "",
      fields: [],
      x: 203,
      y: 149,
      wires: [["be49eb73.ca0d08", "f70db9c5.78f078"]],
    },
    {
      id: "be49eb73.ca0d08",
      type: "debug",
      z: "c9826de2.d8985",
      name: "",
      active: true,
      console: "false",
      complete: "true",
      x: 462,
      y: 100,
      wires: [],
    },
    {
      id: "d6d2012c.8e2d1",
      type: "module-out",
      z: "c9826de2.d8985",
      name: "",
      cname: "Raise a new alarm",
      module: "alarm_management_system",
      event: "create",
      fields: [
        { field: "source", control: "string", type: "str", val: "123" },
        { field: "code", control: "string", type: "msg", val: "code" },
        { field: "text", control: "string", type: "msg", val: "text" },
        { field: "location", control: "string", type: "str", val: "" },
        { field: "set_audio", control: "select", type: "str", val: null },
      ],
      emitsresult: true,
      x: 527,
      y: 199,
      wires: [[], []],
    },
    {
      id: "f70db9c5.78f078",
      type: "function",
      z: "c9826de2.d8985",
      name: "",
      func: 'msg.name = "Craig"\n\nmsg.age = 12 + 23\n\nmsg.lastText = msg.text.split("Edge")[1]\n\nreturn msg;',
      outputs: 1,
      noerr: 0,
      x: 341,
      y: 201,
      wires: [["d6d2012c.8e2d1", "e4935ac9.83f218", "502b2e70.5c0e9"]],
    },
    {
      id: "5b5857b7.2d44f8",
      type: "debug",
      z: "c9826de2.d8985",
      name: "",
      active: true,
      console: "false",
      complete: "true",
      x: 475,
      y: 276,
      wires: [],
    },
    {
      id: "e4935ac9.83f218",
      type: "delay",
      z: "c9826de2.d8985",
      name: "",
      pauseType: "delay",
      timeout: "5",
      timeoutUnits: "seconds",
      rate: "1",
      nbRateUnits: "1",
      rateUnits: "second",
      randomFirst: "1",
      randomLast: "5",
      randomUnits: "seconds",
      drop: false,
      x: 321,
      y: 280,
      wires: [["5b5857b7.2d44f8"]],
    },
    {
      id: "502b2e70.5c0e9",
      type: "switch",
      z: "c9826de2.d8985",
      name: "",
      property: "name",
      propertyType: "msg",
      rules: [
        { t: "eq", v: "Craig", vt: "str" },
        { t: "eq", v: "Sarayu", vt: "str" },
        { t: "eq", v: "D", vt: "str" },
        { t: "else" },
      ],
      checkall: "true",
      outputs: 4,
      x: 337,
      y: 378,
      wires: [
        ["9acf0752.03e128"],
        ["e6b62733.6ae398"],
        ["be701784.53a3a8"],
        ["a40565f9.e2ec98"],
      ],
    },
    {
      id: "9acf0752.03e128",
      type: "debug",
      z: "c9826de2.d8985",
      name: "",
      active: true,
      console: "false",
      complete: "true",
      x: 467,
      y: 326,
      wires: [],
    },
    {
      id: "e6b62733.6ae398",
      type: "debug",
      z: "c9826de2.d8985",
      name: "",
      active: true,
      console: "false",
      complete: "true",
      x: 468,
      y: 365,
      wires: [],
    },
    {
      id: "be701784.53a3a8",
      type: "debug",
      z: "c9826de2.d8985",
      name: "",
      active: true,
      console: "false",
      complete: "true",
      x: 468,
      y: 404,
      wires: [],
    },
    {
      id: "a40565f9.e2ec98",
      type: "debug",
      z: "c9826de2.d8985",
      name: "",
      active: true,
      console: "false",
      complete: "true",
      x: 468,
      y: 444,
      wires: [],
    },
    {
      id: "ca4fe34e.13a2d",
      type: "inject",
      z: "c9826de2.d8985",
      name: "",
      topic: "",
      payload: "",
      payloadType: "date",
      repeat: "",
      crontab: "",
      once: false,
      x: 163,
      y: 58,
      wires: [["e1d633de.6163a"]],
    },
    {
      id: "e1d633de.6163a",
      type: "function",
      z: "c9826de2.d8985",
      name: "setting msg",
      func: 'msg = {"code":"8","text":"Teldio Edge Gateway Test Event","tag":"GATEWAY_SERVER:2025-01-28T14:53:37.797254924Z","_msgid":"d3bfe18.139012"}\n//hello\nreturn msg;',
      outputs: 1,
      noerr: 0,
      x: 316,
      y: 61,
      wires: [["be49eb73.ca0d08"]],
    },
    {
      id: "d2c71aef.5ef7d8",
      type: "comment",
      z: "c9826de2.d8985",
      name: "starting test event",
      info: "",
      x: 497,
      y: 64,
      wires: [],
    },
    {
      id: "6331effc.e6d0c",
      type: "http in",
      z: "c9826de2.d8985",
      name: "",
      url: "/hello",
      method: "get",
      upload: false,
      swaggerDoc: "",
      x: 245,
      y: 509,
      wires: [["1edb50c2.bf2e6f", "e932b788.5aa688"]],
    },
    {
      id: "1edb50c2.bf2e6f",
      type: "debug",
      z: "c9826de2.d8985",
      name: "",
      active: true,
      console: "false",
      complete: "true",
      x: 423,
      y: 518,
      wires: [],
    },
    {
      id: "5dccb793.a40548",
      type: "http response",
      z: "c9826de2.d8985",
      name: "",
      statusCode: "",
      headers: {},
      x: 420,
      y: 566,
      wires: [],
    },
    {
      id: "e932b788.5aa688",
      type: "function",
      z: "c9826de2.d8985",
      name: "setting msg",
      func: 'msg.payload = {"code":"8","text":"Teldio Edge Gateway Test Event","tag":"GATEWAY_SERVER:2025-01-28T14:53:37.797254924Z","_msgid":"d3bfe18.139012"}\n//hello\nreturn msg;',
      outputs: 1,
      noerr: 0,
      x: 258,
      y: 588,
      wires: [["5dccb793.a40548", "910297ce.e47078"]],
    },
    {
      id: "910297ce.e47078",
      type: "debug",
      z: "c9826de2.d8985",
      name: "",
      active: true,
      console: "false",
      complete: "true",
      x: 426,
      y: 612,
      wires: [],
    },
  ];
  // React Flow requires nodeTypes object
  const nodeTypes = { noderedNode: NodeRedNode };

  // React Flow hooks for state
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  // parseNodeRedFlow: convert Node-RED JSON to React Flow nodes/edges
  const parseNodeRedFlow = useCallback((flowJson) => {
    const rfNodes = [];
    const rfEdges = [];

    flowJson.forEach((nrNode) => {
      // If your Node-RED nodes have a property indicating # of outputs, use it here
      // For example, many Node-RED "function" nodes might have a 'outputs' property, etc.
      const outputs = nrNode.wires ? nrNode.wires.length : 0;
      const inputs = nrNode.type === "inject" ? 0 : 1;

      rfNodes.push({
        id: nrNode.id,
        // Node-RED uses (x,y) roughly as node center, offset to top-left
        position: { x: (nrNode.x || 0) - 75, y: (nrNode.y || 0) - 20 },
        data: {
          label: nrNode.name || nrNode.type || "Untitled",
          nodeType: nrNode.type,
          inputs,
          outputs,
        },
        type: "noderedNode",
      });

      // Edges based on "wires" property
      // Each item in 'wires' is an array of node IDs for that output
      if (nrNode.wires && Array.isArray(nrNode.wires)) {
        nrNode.wires.forEach((wireArray) => {
          wireArray.forEach((targetId) => {
            rfEdges.push({
              id: `${nrNode.id}-${targetId}`,
              source: nrNode.id,
              target: targetId,
            });
          });
        });
      }
    });

    return { rfNodes, rfEdges };
  }, []);

  // On mount or whenever flow changes, parse and update
  useEffect(() => {
    if (!flow || !Array.isArray(flow)) return;
    const { rfNodes, rfEdges } = parseNodeRedFlow(flow);
    setNodes(rfNodes);
    setEdges(rfEdges);
  }, [flow, parseNodeRedFlow, setNodes, setEdges]);

  // Optional: a button to refresh the layout or re-parse (if needed)
  const handleRefresh = () => {
    const { rfNodes, rfEdges } = parseNodeRedFlow(flow);
    setNodes(rfNodes);
    setEdges(rfEdges);
  };

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <Button variant="contained" onClick={handleRefresh} sx={{ m: 2 }}>
        Refresh
      </Button>

      <div style={{ width: "100%", height: "85%" }}>
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          fitView
        >
          <MiniMap />
          <Controls />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </div>
    </Box>
  );
}
