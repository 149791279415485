import { CircularProgress } from "@mui/material";
import React, { useState } from "react";

const TEGViewer = () => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div
      style={{
        height: "calc(100dvh - 100px)",
        overflow: "hidden",
      }}
    >
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ color: "#A0AD39", marginTop: "30px" }} />
        </div>
      )}
      {/* <iframe
        src="https://aviemore.teldio.com"
        sandbox="allow-same-origin allow-scripts allow-forms"
        allow="fullscreen"
        allowtransparency="true"
        allowpaymentrequest="true"
        title={`TEG`}
      ></iframe> */}
      <iframe
        src={"https://aviemore.teldio.com"}
        sandbox={
          "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
        }
        onLoad={handleLoad}
        referrerPolicy={"origin"}
        credentialless="true"
        style={{
          border: "none",
          width: "100%",
          height: "100%",
          marginTop: "0px",
          transform: "none",
        }}
        title={`TEG`}
      />
    </div>
  );
};

export default TEGViewer;
