import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../firebase/contexts/AuthContext";
import {
  ColorButton,
  RoundedCard,
  RoundedTextField,
  TeldioTextField,
} from "../MUI_Components/Components";
import { Button } from "@mui/material";

const authErrors = {
  "auth/invalid-email": "Email address not valid",
  "auth/wrong-password": "Incorrect username or password",
};

export default function SignIn() {
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    if (data.get("email") === "" || data.get("email") === "") {
      setError("You must enter an email and password");
      return;
    }
    try {
      setError("");
      setLoading(true);
      await login(data.get("email"), data.get("password"));
      const redirectPath = localStorage.getItem("redirectPath");
      navigate(redirectPath || "/");
    } catch (err) {
      console.log(err.code);
      if (authErrors[err.code]) {
        setError(authErrors[err.code]);
      } else {
        setError("Failed to log in");
      }
    }
    setLoading(false);
  }

  return (
    <div
      style={{
        height: "80dvh",
        width: "100dvw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <RoundedCard>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="assets/teldio_logo.png"
              alt="Teldio logo"
              style={{ width: "150px" }}
            />
            <Typography component="h1" variant="h5" sx={{ marginTop: "30px" }}>
              Sign in
            </Typography>
            {error && (
              <Alert severity="error" sx={{ marginTop: "5px" }}>
                {error}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              <TeldioTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TeldioTextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ColorButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                >
                  Sign In
                </ColorButton>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={() => navigate("/forgot-password")}>
                  Forgot your password?
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      </RoundedCard>
    </div>
  );
}
