import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, MenuItem } from "@mui/material";
import { ref, onValue, update, remove } from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import {
  SquaredButton,
  SquaredButtonBase,
  SquaredButtonGreen,
  TeldioTextField,
} from "../../MUI_Components/Components";
import { colors } from "../../Helper/utils";
import { useSettings } from "../../Contexts/SettingsContext";
import { useThemeContext } from "../../Contexts/ThemeDetailsContext";

const DividerCard = ({ taskId, columnId, taskPath, boardPath }) => {
  // State for modal open
  const [open, setOpen] = useState(false);
  const { settings } = useSettings();
  const themeDetails = useThemeContext();

  // State for color and text
  const [color, setColor] = useState("default"); // Set default as initial value
  const [text, setText] = useState("");
  const [dividerData, setDividerData] = useState();

  const { currentUser } = useAuth();
  const db = getDB();

  // Path to the divider in the database
  const dividerPath = `${taskPath}`;

  // Get the current theme font color
  const defaultFontColor = themeDetails[settings.cardColor]?.font || "black";

  useEffect(() => {
    setColor("default"); // Initialize with "default"
  }, [themeDetails]);

  // Fetch divider data from backend
  useEffect(() => {
    const dividerRef = ref(db, dividerPath);
    const unsubscribe = onValue(dividerRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log("data", data);
        // If color is explicitly set to "default" or not set, use default
        setColor(data.color || "default");
        setText(data.text || "");
        if (!data?.color && !data?.text) {
          handleOpen();
        }
        setDividerData(data);
        console.log(data);
      }
    });
    return () => unsubscribe();
  }, [db, dividerPath]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle save
  const handleSave = () => {
    const dividerRef = ref(db, dividerPath);
    update(dividerRef, {
      color,
      text,
    });
    handleClose();
  };

  // Handle delete
  const handleDelete = () => {
    const dividerRef = ref(db, dividerPath);
    remove(dividerRef);
    handleClose();
  };

  // Function to get the actual color to display
  const getDisplayColor = (colorValue) => {
    return colorValue === "default" ? defaultFontColor : colorValue;
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          my: 2,
          userSelect: "none",
          py: 1,
          mr: "5px",
        }}
        onKeyDown={(e) => e.stopPropagation()} // Prevent keydown events from bubbling up
      >
        <Box
          sx={{
            flexGrow: 1,
            borderBottom: `4px solid ${getDisplayColor(
              dividerData?.color || color
            )}`,
          }}
        ></Box>
        {text && (
          <Typography
            sx={{
              mx: 1,
              color: getDisplayColor(dividerData?.color || color),
              whiteSpace: "wrap",
              fontWeight: "bold",
              maxWidth: "80%",
              textAlign: "center",
            }}
          >
            {dividerData?.text || text}
          </Typography>
        )}
        <Box
          sx={{
            flexGrow: 1,
            borderBottom: `4px solid ${getDisplayColor(
              dividerData?.color || color
            )}`,
          }}
        ></Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "clamp(200px, 90vw, 400px)",
            bgcolor: themeDetails[settings.cardColor]?.primary || "white",
            color: themeDetails[settings.cardColor]?.font || "black",
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "15px",
            outline: "none",
          }}
        >
          <Typography variant="h6" sx={{ color: defaultFontColor }}>
            Edit Divider
          </Typography>
          <TeldioTextField
            label="Text"
            autocomplete="off"
            sx={{
              autocomplete: "off",
              color: themeDetails[settings.cardColor]?.font || "black",
              "& .MuiInputLabel-root": {
                color: defaultFontColor,
              },
              "& .MuiOutlinedInput-root": {
                color: defaultFontColor,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${defaultFontColor}40`, // adding transparency
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${defaultFontColor}80`, // adding transparency
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: defaultFontColor,
              },
            }}
            value={text}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setText(e.target.value);
            }}
            fullWidth
          />
          <TeldioTextField
            select
            inputProps={{
              MenuProps: {
                MenuListProps: {
                  sx: {
                    backgroundColor:
                      themeDetails[settings.cardColor]?.primary || "white",
                    color: themeDetails[settings.cardColor]?.font || "black",
                  },
                },
              },
            }}
            sx={{
              "& .MuiInputLabel-root": {
                color: defaultFontColor,
              },
              "& .MuiOutlinedInput-root": {
                color: defaultFontColor,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${defaultFontColor}40`, // adding transparency
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${defaultFontColor}80`, // adding transparency
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: defaultFontColor,
              },
            }}
            label="Preset Color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            fullWidth
            margin="normal"
            placeholderColor={defaultFontColor}
          >
            {/* Add Default option at the beginning of the dropdown */}
            <MenuItem
              key="default"
              value="default"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: themeDetails[settings.cardColor]?.primary,
                  color: themeDetails[settings.cardColor]?.font || "black",
                },
                "&.Mui-selected:hover": {
                  backgroundColor:
                    themeDetails[settings.cardColor]?.dark || "rgba(0,0,0,0.1)",
                },
                "&:hover": {
                  backgroundColor:
                    themeDetails[settings.cardColor]?.dark || "rgba(0,0,0,0.1)",
                },
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    height: "20px",
                    backgroundColor: defaultFontColor,
                  }}
                />
                <Typography sx={{ ml: 1 }}>Default (Theme)</Typography>
              </Box>
            </MenuItem>

            {/* Regular color options */}
            {colors.map((colorOption) => (
              <MenuItem
                key={colorOption}
                value={colorOption}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: themeDetails[settings.cardColor]?.primary,
                    color: themeDetails[settings.cardColor]?.font || "black",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor:
                      themeDetails[settings.cardColor]?.dark || colorOption,
                  },
                  "&:hover": {
                    backgroundColor:
                      themeDetails[settings.cardColor]?.dark || colorOption,
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "20px",
                    backgroundColor: colorOption,
                  }}
                />
              </MenuItem>
            ))}
          </TeldioTextField>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "46.5px",
              mt: 2,
            }}
          >
            <SquaredButtonBase
              onClick={handleDelete}
              variant="contained"
              color="error"
              sx={{ marginBottom: 0 }}
            >
              Delete
            </SquaredButtonBase>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 1,
                alignItems: "flex-start",
              }}
            >
              <SquaredButton onClick={handleClose}>Cancel</SquaredButton>
              <SquaredButtonGreen onClick={handleSave}>Save</SquaredButtonGreen>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DividerCard;
