import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Backdrop,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import {
  Place as PlaceIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Route as RouteIcon,
  Navigation as NavigationIcon,
  UploadFile as UploadFileIcon,
  Satellite as SatelliteIcon,
  Map as MapIcon,
} from "@mui/icons-material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

////////////////////////////////////////////////////////////////////////////////
// 1) Inline CSS for our custom draggable bubbles (color #A0AD39, no handle)
////////////////////////////////////////////////////////////////////////////////
const customMarkerCSS = `
/* Leaflet defaults to a white square for divIcons. We override it */
.leaflet-div-icon {
  background: transparent;
  border: none;
}

/* Our main bubble style (#A0AD39) */
.custom-marker {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #A0AD39;
  color: #fff;
  border-radius: 50%;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  border: 2px solid #fff;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  cursor: grab; /* So you see a grab cursor */
  pointer-events: auto;
}

/* The number is centered in the circle */
.custom-marker .marker-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`;

function InjectCustomMarkerStyles() {
  useEffect(() => {
    const styleEl = document.createElement("style");
    styleEl.innerHTML = customMarkerCSS;
    document.head.appendChild(styleEl);
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);
  return null;
}

// ---------------------------------------------------------------------------
const STREET_TILE_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const SATELLITE_TILE_URL =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
const MIN_ZOOM_REQUIRED = 14;

function TrufleetSimulator() {
  // ------------------- React States -------------------
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [error, setError] = useState(null);

  // Autocomplete
  const [searchValue, setSearchValue] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);

  // Cache for search results
  const searchCache = useRef({});

  // OSRM route geometry
  const [routeCoordinates, setRouteCoordinates] = useState([]);

  // Map style toggles
  const [useSatellite, setUseSatellite] = useState(false);

  // Overpass route generation spinner
  const [isGeneratingRoute, setIsGeneratingRoute] = useState(false);

  // Cancel Route dialog
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  // Current map zoom
  const [mapZoom, setMapZoom] = useState(13);

  // Leaflet references
  const mapRef = useRef(null);
  const tileLayerRef = useRef(null);

  // Show/hide toggles for stops/coords in the UI
  const [showAllStops, setShowAllStops] = useState(false);
  const [showAllRouteCoords, setShowAllRouteCoords] = useState(false);

  // Speed input
  const [speedValue, setSpeedValue] = useState("");

  // Number of stops for auto-generation
  const [stopCount, setStopCount] = useState(6);

  // If true, skip OSRM (for imported routes). If false, use OSRM.
  const [isImportedRoute, setIsImportedRoute] = useState(false);

  // -------------------------------------------------------------------------
  // Initialize map once
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map("map").setView([45.4215, -75.6972], 13);

      tileLayerRef.current = L.tileLayer(STREET_TILE_URL, {
        attribution: "© OpenStreetMap contributors",
      }).addTo(map);

      mapRef.current = map;
      map.on("click", handleMapClick);
      map.on("moveend", () => setBounds(map.getBounds()));
      map.on("zoomend", () => setMapZoom(map.getZoom()));
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  // -------------------------------------------------------------------------
  // Autocomplete logic
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (searchValue.length < 3) {
      setSearchOptions([]);
      return;
    }
    if (debounceTimer) clearTimeout(debounceTimer);

    const newTimer = setTimeout(() => {
      performSearch(searchValue);
    }, 500);
    setDebounceTimer(newTimer);

    return () => {
      if (newTimer) clearTimeout(newTimer);
    };
  }, [searchValue]);

  const performSearch = async (input) => {
    if (!input) return;
    if (searchCache.current[input]) {
      setSearchOptions(searchCache.current[input]);
      return;
    }
    setIsSearching(true);
    try {
      const url =
        "https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&limit=5&q=" +
        encodeURIComponent(input);
      const resp = await fetch(url);
      if (!resp.ok) {
        throw new Error(`${resp.status} ${resp.statusText}`);
      }
      const data = await resp.json();
      searchCache.current[input] = data;
      setSearchOptions(data);
      setError(null);
    } catch (err) {
      console.error("Autocomplete fetch error:", err);
      setError(
        "Search error (CORS or rate limit). Please wait, zoom in, or try a different address."
      );
    } finally {
      setIsSearching(false);
    }
  };

  // -------------------------------------------------------------------------
  // Whenever markers or isImportedRoute changes, re-draw
  // -------------------------------------------------------------------------
  useEffect(() => {
    updateMapMarkers(markers, isImportedRoute);
  }, [markers, isImportedRoute]);

  // -------------------------------------------------------------------------
  // Toggle Satellite vs Street
  // -------------------------------------------------------------------------
  const toggleMapLayer = () => {
    if (!mapRef.current || !tileLayerRef.current) return;
    mapRef.current.removeLayer(tileLayerRef.current);

    const newLayerUrl = useSatellite ? STREET_TILE_URL : SATELLITE_TILE_URL;
    const newAttribution = useSatellite
      ? "© OpenStreetMap contributors"
      : "Source: Esri, Maxar, Earthstar Geographics, and the GIS User Community";

    tileLayerRef.current = L.tileLayer(newLayerUrl, {
      attribution: newAttribution,
    }).addTo(mapRef.current);

    setUseSatellite(!useSatellite);
  };

  // -------------------------------------------------------------------------
  // Map click => add marker at clicked coords
  // -------------------------------------------------------------------------
  const handleMapClick = (e) => {
    if (isGeneratingRoute) return;
    const { lat, lng } = e.latlng;
    addMarker({ lat, lng });
  };

  const addMarker = async (position) => {
    try {
      // Reverse-geocode immediate name for the new marker
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.lat}&lon=${position.lng}`
      );
      const data = await response.json();
      const newMarker = {
        id: Date.now(),
        position,
        name: data.display_name || `Location ${markers.length + 1}`,
      };
      // Manually added => not imported route
      setIsImportedRoute(false);
      setMarkers((prev) => [...prev, newMarker]);
    } catch (error) {
      setError("Failed to get location name");
      console.error("Error adding marker:", error);
    }
  };

  const removeMarker = (id) => {
    if (isGeneratingRoute) return;
    setMarkers((prev) => prev.filter((m) => m.id !== id));
  };

  // -------------------------------------------------------------------------
  // Update all Leaflet markers & polylines
  // -------------------------------------------------------------------------
  const updateMapMarkers = (markersArray, isImported) => {
    if (!mapRef.current) return;

    // 1) Clear existing markers, polylines, AND circleMarkers
    mapRef.current.eachLayer((layer) => {
      if (
        layer instanceof L.Marker ||
        layer instanceof L.Polyline ||
        layer instanceof L.CircleMarker
      ) {
        mapRef.current.removeLayer(layer);
      }
    });

    // 2) Add new markers (the big #A0AD39 bubbles)
    markersArray.forEach((marker, index) => {
      const icon = L.divIcon({
        html: `
          <div class="custom-marker">
            <div class="marker-number">${index + 1}</div>
          </div>
        `,
        className: "",
      });

      const leafletMarker = L.marker(marker.position, {
        icon,
        draggable: true,
      })
        .addTo(mapRef.current)
        .bindPopup(marker.name);

      // Listen for dragend => update lat/lng + re-fetch address
      leafletMarker.on("dragend", async (e) => {
        const { lat, lng } = e.target.getLatLng();
        try {
          // Reverse-geocode for the new location
          const resp = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
          );
          const data = await resp.json();
          const newName = data.display_name || "Unknown location";

          // Update that marker's position & name in our React state
          setMarkers((prevMarkers) =>
            prevMarkers.map((m) =>
              m.id === marker.id
                ? { ...m, position: { lat, lng }, name: newName }
                : m
            )
          );
        } catch (dragErr) {
          console.error("Error updating marker name on drag:", dragErr);
        }
      });
    });

    // 3) Draw route if we have 2+ markers
    if (markersArray.length > 1) {
      if (isImported) {
        // Direct line between points
        const latLngs = markersArray.map((m) => [
          m.position.lat,
          m.position.lng,
        ]);
        L.polyline(latLngs, {
          color: "blue",
          weight: 3,
          opacity: 0.7,
        }).addTo(mapRef.current);

        // Add red dots at each coordinate
        latLngs.forEach(([lat, lng]) => {
          L.circleMarker([lat, lng], {
            radius: 3,
            color: "red",
            fillColor: "red",
            fillOpacity: 1,
          }).addTo(mapRef.current);
        });

        setRouteCoordinates(latLngs);
      } else {
        // Use OSRM route
        drawRoute(markersArray);
      }
    } else {
      setRouteCoordinates([]);
    }
  };

  // -------------------------------------------------------------------------
  // Generate route (Overpass + OSRM)
  // -------------------------------------------------------------------------
  const generateSuggestedRoute = async () => {
    if (!bounds) return;
    if (stopCount < 2) {
      setError("Number of stops must be at least 2.");
      return;
    }

    setIsGeneratingRoute(true);
    disableMapInteraction(true);

    try {
      const { _northEast, _southWest } = bounds;
      const query = `
        [out:json];
        way[highway~"^(primary|secondary|tertiary)$"](${_southWest.lat},${_southWest.lng},${_northEast.lat},${_northEast.lng});
        (._;>;);
        out body;
      `;
      const response = await fetch("https://overpass-api.de/api/interpreter", {
        method: "POST",
        body: query,
      });

      const data = await response.json();
      const roadNodes = data.elements.filter((el) => el.type === "node");
      if (!roadNodes.length) {
        throw new Error("No highway nodes found in this area.");
      }

      // pick (stopCount - 1) random-ish points, then circle back
      const suggestedPoints = selectRoutePoints(roadNodes, stopCount - 1);
      if (!suggestedPoints.length) {
        throw new Error("No route points found in this area.");
      }
      suggestedPoints.push(suggestedPoints[0]);

      setIsImportedRoute(false);
      // Clear existing markers
      setMarkers([]);

      // For each point, addMarker
      for (let point of suggestedPoints) {
        await addMarker({ lat: point.lat, lng: point.lon });
      }
    } catch (error) {
      setError(
        error.message || "Failed to generate suggested route, please try again."
      );
      console.error("Error generating route:", error);
    } finally {
      setIsGeneratingRoute(false);
      disableMapInteraction(false);
    }
  };

  const selectRoutePoints = (nodes, count) => {
    if (!nodes || !nodes.length) return [];
    const step = Math.floor((nodes.length / count) * 1.5) || 1;
    return nodes.filter((_, i) => i % step === 0).slice(0, count);
  };

  // -------------------------------------------------------------------------
  // Use OSRM to build a route
  // -------------------------------------------------------------------------
  const drawRoute = async (markersArray) => {
    if (markersArray.length < 2) return;
    try {
      const coords = markersArray
        .map((m) => `${m.position.lng},${m.position.lat}`)
        .join(";");
      const response = await fetch(
        `https://router.project-osrm.org/route/v1/driving/${coords}?overview=full`
      );
      const data = await response.json();

      if (data.routes && data.routes[0]) {
        const decoded = decodePolyline(data.routes[0].geometry);

        // Draw the polyline
        L.polyline(decoded, {
          color: "blue",
          weight: 3,
          opacity: 0.7,
        }).addTo(mapRef.current);

        // Add red dots at each coordinate
        decoded.forEach(([lat, lng]) => {
          L.circleMarker([lat, lng], {
            radius: 3,
            color: "red",
            fillColor: "red",
            fillOpacity: 1,
          }).addTo(mapRef.current);
        });

        setRouteCoordinates(decoded);
      }
    } catch (error) {
      setError("Failed to draw route via OSRM");
      console.error("Error drawing route:", error);
    }
  };

  // -------------------------------------------------------------------------
  // Temporarily disable map interactions
  // -------------------------------------------------------------------------
  const disableMapInteraction = (disable) => {
    if (!mapRef.current) return;
    if (disable) {
      mapRef.current.dragging.disable();
      mapRef.current.scrollWheelZoom.disable();
      mapRef.current.doubleClickZoom.disable();
      mapRef.current.boxZoom.disable();
      mapRef.current.keyboard.disable();
    } else {
      mapRef.current.dragging.enable();
      mapRef.current.scrollWheelZoom.enable();
      mapRef.current.doubleClickZoom.enable();
      mapRef.current.boxZoom.enable();
      mapRef.current.keyboard.enable();
    }
  };

  // -------------------------------------------------------------------------
  // Close Loop button => duplicates first stop, making a loop
  // -------------------------------------------------------------------------
  const closeLoop = () => {
    if (markers.length < 2) return;
    const first = markers[0];
    const newMarker = {
      id: Date.now(),
      position: { ...first.position },
      name: first.name + " (Loop End)",
    };
    setIsImportedRoute(false);
    setMarkers((prev) => [...prev, newMarker]);
  };

  // -------------------------------------------------------------------------
  // Export routeCoordinates as TruFleet CSV
  // -------------------------------------------------------------------------
  const exportForTruFleet = () => {
    if (!routeCoordinates.length) return;
    const csvLines = [];
    csvLines.push("* Can use Teldio Tools to generate routes");
    csvLines.push(
      "*Name Longitude Latitude Speed (km/h) Generate IO Bus Stop ID"
    );

    routeCoordinates.forEach(([lat, lng]) => {
      csvLines.push([, lng, lat, speedValue || "", "", ""].join(","));
    });

    downloadCsv(csvLines.join("\n"), "TruFleetRoute.csv");
  };

  const downloadCsv = (csvContent, filename) => {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // -------------------------------------------------------------------------
  // Import CSV => set markers (skip OSRM)
  // -------------------------------------------------------------------------
  const handleFileImport = (e) => {
    if (isGeneratingRoute) return;
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (evt) => {
      const text = evt.target.result;
      const lines = text.split(/\r?\n/).filter((l) => l.trim() !== "");
      if (!lines.length) {
        setError("Invalid CSV or empty file.");
        return;
      }

      const dataMarkers = [];
      // CSV format: Name,Longitude,Latitude
      for (let i = 0; i < lines.length; i++) {
        const [name, lngStr, latStr] = lines[i].split(",");
        if (!lngStr || !latStr) {
          console.warn("Skipping invalid row:", lines[i]);
          continue;
        }
        const lat = parseFloat(latStr);
        const lng = parseFloat(lngStr);
        if (isNaN(lat) || isNaN(lng)) {
          console.warn("Skipping invalid row:", lines[i]);
          continue;
        }
        dataMarkers.push({
          id: Date.now() + i,
          name: name || `Location ${i + 1}`,
          position: { lat, lng },
        });
      }

      setIsImportedRoute(true);
      setMarkers(dataMarkers);
    };
    reader.readAsText(file);
  };

  // -------------------------------------------------------------------------
  // Cancel Route dialog
  // -------------------------------------------------------------------------
  const handleCancelRoute = () => {
    setShowCancelDialog(true);
  };

  const confirmCancelRoute = () => {
    setShowCancelDialog(false);
    setMarkers([]);
    setRouteCoordinates([]);
    setShowAllStops(false);
    setShowAllRouteCoords(false);
    setSpeedValue("");
    setIsImportedRoute(false);
  };

  // -------------------------------------------------------------------------
  // Render
  // -------------------------------------------------------------------------
  const canGenerateRoute = !isGeneratingRoute && mapZoom >= MIN_ZOOM_REQUIRED;
  const stopsToShow = showAllStops ? markers : markers.slice(0, 5);
  const coordsToShow = showAllRouteCoords
    ? routeCoordinates
    : routeCoordinates.slice(0, 5);

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 100px)", gap: 2, p: 2 }}>
      {/* Inject CSS for our #A0AD39 bubbles */}
      <InjectCustomMarkerStyles />

      {/* Left side: Map */}
      <Box sx={{ flex: 2, position: "relative" }}>
        <div id="map" style={{ height: "100%", borderRadius: 4 }} />

        {/* Satellite toggle (top-left) */}
        <Box
          sx={{
            position: "absolute",
            top: 80,
            left: 8,
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Tooltip
            title={useSatellite ? "Switch to Street" : "Switch to Satellite"}
          >
            <IconButton
              sx={{ color: "black", backgroundColor: "white" }}
              onClick={toggleMapLayer}
              disabled={isGeneratingRoute}
            >
              {useSatellite ? <MapIcon /> : <SatelliteIcon />}
            </IconButton>
          </Tooltip>
        </Box>

        {/* Top-right controls (Generate, Close Loop, Cancel) */}
        <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1000 }}>
          <Select
            size="small"
            value={stopCount}
            onChange={(e) => setStopCount(e.target.value)}
            sx={{ mr: 1, bgcolor: "white" }}
            disabled={isGeneratingRoute}
          >
            <MenuItem value={3}>2 Stops</MenuItem>
            <MenuItem value={6}>5 Stops</MenuItem>
            <MenuItem value={9}>8 Stops</MenuItem>
            <MenuItem value={11}>10 Stops</MenuItem>
            <MenuItem value={16}>15 Stops</MenuItem>
          </Select>

          {/* Generate Route */}
          <Tooltip
            title={
              isGeneratingRoute
                ? "Generating route..."
                : mapZoom < MIN_ZOOM_REQUIRED
                ? `Zoom in (>= ${MIN_ZOOM_REQUIRED}) to generate`
                : `Generate a route with ${stopCount} stops`
            }
          >
            <span>
              <Button
                variant="contained"
                onClick={generateSuggestedRoute}
                disabled={!canGenerateRoute}
                startIcon={<RouteIcon />}
                sx={{ mr: 1 }}
              >
                AI Generate
              </Button>
            </span>
          </Tooltip>

          {/* Close Loop button */}
          <Tooltip title="Add a stop matching the first stop's location">
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={closeLoop}
                disabled={markers.length < 2 || isGeneratingRoute}
                sx={{ mr: 1 }}
              >
                Close Loop
              </Button>
            </span>
          </Tooltip>

          {/* Cancel Route */}
          {markers.length > 0 && (
            <Tooltip title="Clear all stops and route">
              <span>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCancelRoute}
                  disabled={isGeneratingRoute}
                >
                  Cancel
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>

        {/* Spinner Backdrop */}
        <Backdrop
          open={isGeneratingRoute}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
          <Typography sx={{ ml: 2 }}>Generating route...</Typography>
        </Backdrop>
      </Box>

      {/* Right side: Sidebar */}
      <Card sx={{ flex: 1, maxWidth: 420, height: "100%", overflow: "auto" }}>
        <CardHeader
          title="TruFleet Simulator"
          subheader="Add points manually or generate a route"
        />
        <CardContent>
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setError(null)}
            >
              {error}
            </Alert>
          )}

          {/* Autocomplete Search */}
          <Box sx={{ mb: 2 }}>
            <Autocomplete
              options={searchOptions}
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : option.display_name || "No label"
              }
              inputValue={searchValue}
              onInputChange={(event, newValue) => {
                setSearchValue(newValue);
              }}
              onChange={(event, selected) => {
                if (selected && selected.lat && selected.lon) {
                  mapRef.current.setView(
                    [parseFloat(selected.lat), parseFloat(selected.lon)],
                    14
                  );
                }
              }}
              loading={isSearching}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search location"
                  variant="outlined"
                  size="small"
                  helperText="Type at least 3 characters"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isSearching ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>

          {/* Instructions */}
          <Alert color="primary" sx={{ mb: 2 }}>
            <strong>Manual Route:</strong> Click on the map to drop a stop. Drag
            a bubble to move it — the address updates in the list.
            <br />
            <strong>Auto Generate:</strong> Zoom must be {MIN_ZOOM_REQUIRED}+
            (current zoom: {mapZoom}).
          </Alert>

          {/* Import CSV */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Import CSV (Stops)
            </Typography>
            <Button
              variant="contained"
              component="label"
              startIcon={<UploadFileIcon />}
              disabled={isGeneratingRoute}
              fullWidth
            >
              Choose CSV
              <input
                hidden
                type="file"
                accept=".csv"
                onChange={handleFileImport}
              />
            </Button>
            <Typography variant="body2" color="text.secondary">
              Format: Name,Longitude,Latitude (one stop per line)
            </Typography>
          </Box>

          {/* Speed input */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Speed (optional)
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="number"
              placeholder="e.g. 20"
              value={speedValue}
              onChange={(e) => setSpeedValue(e.target.value)}
              helperText="Used when exporting to CSV"
            />
          </Box>

          {/* Stops list */}
          {markers.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom>
                Stops ({markers.length})
              </Typography>
              <List>
                {stopsToShow.map((marker, index) => (
                  <ListItem
                    key={marker.id}
                    sx={{
                      mb: 1,
                      bgcolor: "grey.50",
                      borderRadius: 1,
                    }}
                  >
                    <ListItemIcon>
                      <NavigationIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Stop ${index + 1}`}
                      secondary={marker.name}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => removeMarker(marker.id)}
                        color="error"
                        disabled={isGeneratingRoute}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {markers.length > 5 && (
                <Box sx={{ textAlign: "center", mb: 2 }}>
                  <Button
                    variant="text"
                    onClick={() => setShowAllStops((prev) => !prev)}
                  >
                    {showAllStops ? "Show Less" : "Show More"}
                  </Button>
                </Box>
              )}
            </>
          )}

          {/* Route coordinate list */}
          {routeCoordinates.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mt: 4 }}>
                Full Route Coordinates ({routeCoordinates.length})
              </Typography>
              <List dense>
                {coordsToShow.map(([lat, lng], idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      <PlaceIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={`Lng: ${lng}, Lat: ${lat}`} />
                  </ListItem>
                ))}
              </List>
              {routeCoordinates.length > 5 && (
                <Box sx={{ textAlign: "center", mb: 2 }}>
                  <Button
                    variant="text"
                    onClick={() => setShowAllRouteCoords((prev) => !prev)}
                  >
                    {showAllRouteCoords ? "Show Less" : "Show More"}
                  </Button>
                </Box>
              )}

              {/* Export button */}
              <Button
                fullWidth
                variant="contained"
                onClick={exportForTruFleet}
                startIcon={<DownloadIcon />}
                sx={{ mt: 2 }}
                disabled={isGeneratingRoute}
              >
                Export For TruFleet
              </Button>
            </>
          )}
        </CardContent>
      </Card>

      {/* Cancel Route dialog */}
      <Dialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
      >
        <DialogTitle>Cancel Route</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to clear all stops and the current route?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCancelDialog(false)}>No</Button>
          <Button
            onClick={confirmCancelRoute}
            color="error"
            variant="contained"
          >
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// ---------------------------------------------------------------------------
// 3) Polyline decoder for OSRM geometry
// ---------------------------------------------------------------------------
function decodePolyline(str, precision = 5) {
  let index = 0,
    lat = 0,
    lng = 0,
    coordinates = [],
    shift = 0,
    result = 0,
    byte = null,
    latitude_change,
    longitude_change,
    factor = Math.pow(10, precision);

  while (index < str.length) {
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;
    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;
    lat += latitude_change;
    lng += longitude_change;
    coordinates.push([lat / factor, lng / factor]);
  }
  return coordinates;
}

export default TrufleetSimulator;
