import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  palette: {
    primary: {
      main: "#A0AD39",
    },
  },
});
root.render(
  <ThemeProvider theme={theme}>
    <App />{" "}
  </ThemeProvider>
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/sw.js`)
      .then((registration) => {
        console.log("Service worker registered:", registration);

        // If there's a waiting service worker (i.e., a new version is ready)
        if (registration.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }

        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;
          newWorker.addEventListener("statechange", () => {
            if (
              newWorker.state === "installed" &&
              navigator.serviceWorker.controller
            ) {
              // When the new service worker is installed, reload the page
              window.location.reload();
            }
          });
        });
      })
      .catch((error) => {
        console.error("Error registering service worker:", error);
      });
  });
}

// Trigger a reload when a new service worker takes control
navigator.serviceWorker.addEventListener("controllerchange", () => {
  window.location.reload(); // Reload when the service worker is updated
});

navigator.serviceWorker.addEventListener("controllerchange", () => {
  window.location.reload(); // Reload when a new service worker takes control
});

navigator.serviceWorker.addEventListener("controllerchange", () => {
  window.location.reload(); // Reload when a new service worker takes control
});
