import CelebrationIcon from "@mui/icons-material/Celebration";
import PushPinIcon from "@mui/icons-material/PushPin";
import RemoveIcon from "@mui/icons-material/Remove";
import { Badge, Box, IconButton, Typography } from "@mui/material";
import { ref, runTransaction } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useSettings } from "../../Contexts/SettingsContext";
import { useAuth } from "../../firebase/contexts/AuthContext";
import { getDB } from "../../firebase/firebase";
import { convertToArray } from "../../Helper/utils";
import CreateBoard from "./CreateBoard";

const HomePage = ({
  isSidebarOpen,
  ownBoards,
  collaboratedBoards,
  handleCollaborationBoardClick,
  handleBoardClick,
}) => {
  const { currentUser } = useAuth();
  const [handleOpenFromParent, setHandleOpenFromParent] = useState(false);
  const [hoveredBoard, setHoveredBoard] = useState(null);
  const db = getDB();
  const { settings } = useSettings();

  useEffect(() => {
    document.title = `Boards | Teldio Tools`;
  }, []);

  const handlePinBoard = (boardID) => {
    const userSettingsPath = `/uid/${currentUser.uid}/settings`;
    const pinnedBoardsRef = ref(db, `${userSettingsPath}/pinnedBoards`);

    runTransaction(pinnedBoardsRef, (currentData) => {
      if (currentData && !currentData.includes(boardID)) {
        return [...currentData, boardID];
      }
      return currentData || [boardID];
    });
  };

  const handleUnpinBoard = (boardID) => {
    const userSettingsPath = `/uid/${currentUser.uid}/settings`;
    const pinnedBoardsRef = ref(db, `${userSettingsPath}/pinnedBoards`);

    runTransaction(pinnedBoardsRef, (currentData) => {
      if (currentData) {
        return currentData.filter((id) => id !== boardID);
      }
      return currentData;
    });
  };

  const isPinned = (boardId) => settings?.pinnedBoards?.includes(boardId);

  return (
    <Box>
      <div
        style={{
          marginLeft: isSidebarOpen ? 260 : 65,
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>
            Home
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "20px" }}>
            Select a board or create a new one to get started.
          </Typography>
        </div>

        {/* Pinned Boards Section */}
        {settings?.pinnedBoards?.length > 0 && (
          <>
            <Typography
              variant="h5"
              sx={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              Pinned
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {settings.pinnedBoards.map((boardId) => {
                const board = ownBoards[boardId] || collaboratedBoards[boardId];
                const isOwnBoard = !!ownBoards[boardId];

                return (
                  board && (
                    <div
                      key={boardId}
                      onMouseEnter={() => setHoveredBoard(boardId)}
                      onMouseLeave={() => setHoveredBoard(null)}
                      onClick={() =>
                        isOwnBoard
                          ? handleBoardClick(boardId)
                          : handleCollaborationBoardClick(boardId, board.owner)
                      }
                    >
                      <div
                        className="board-item-big board-hover"
                        style={{
                          position: "relative",
                          display: "flex",
                          cursor: "pointer",
                          color: "white",
                          alignItems: "center",
                          marginLeft: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "200px",
                            height: "120px",
                            backgroundColor: board?.background || "darkgray",
                            backgroundImage: board?.background?.startsWith(
                              "http"
                            )
                              ? `url(${board?.background})`
                              : "none",
                            borderRadius: "2px",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "rgba(3, 3, 3, 0.6)",
                              width: "99%",
                              position: "absolute",
                              left: 0,
                              top: 8,
                              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.4)",
                              backdropFilter: "blur(1px)",
                              marginLeft: 1,
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ paddingX: "10px" }}
                            >
                              {board?.title || "Untitled"}
                            </Typography>
                          </div>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {hoveredBoard === boardId && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUnpinBoard(boardId);
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          )}
                          {convertToArray(board.activity)?.filter(
                            (incident) =>
                              incident.acknowledged === false &&
                              incident.mentionedUser === currentUser.email
                          ).length > 0 && (
                            <Badge
                              badgeContent={
                                convertToArray(board.activity)?.filter(
                                  (incident) =>
                                    incident.acknowledged === false &&
                                    incident.mentionedUser === currentUser.email
                                ).length
                              }
                              color="error"
                            >
                              <CelebrationIcon />
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </>
        )}

        <Typography
          variant="h5"
          sx={{ marginBottom: "20px", marginTop: "40px", fontWeight: "bold" }}
        >
          My Boards
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(ownBoards)
            .filter((boardId) => !isPinned(boardId))
            .map((boardId) => (
              <div
                key={boardId}
                onMouseEnter={() => setHoveredBoard(boardId)}
                onMouseLeave={() => setHoveredBoard(null)}
                onClick={() => handleBoardClick(boardId)}
              >
                <div
                  className="board-item-big board-hover"
                  style={{
                    position: "relative",
                    display: "flex",
                    cursor: "pointer",
                    color: "white",
                    alignItems: "center",
                    marginLeft: "20px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      height: "120px",
                      backgroundColor:
                        ownBoards[boardId]?.background || "darkgray",
                      backgroundImage: ownBoards[
                        boardId
                      ]?.background?.startsWith("http")
                        ? `url(${ownBoards[boardId]?.background})`
                        : "none",
                      borderRadius: "2px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgba(3, 3, 3, 0.6)",
                        width: "99%",
                        position: "absolute",
                        left: 0,
                        top: 8,
                        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.4)",
                        backdropFilter: "blur(1px)",
                        marginLeft: 1,
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ paddingX: "10px" }}>
                        {ownBoards[boardId]?.title || "Untitled"}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {hoveredBoard === boardId && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePinBoard(boardId);
                        }}
                      >
                        <PushPinIcon />
                      </IconButton>
                    )}
                    {convertToArray(ownBoards[boardId].activity)?.filter(
                      (incident) =>
                        incident.acknowledged === false &&
                        incident.mentionedUser === currentUser.email
                    ).length > 0 && (
                      <Badge
                        badgeContent={
                          convertToArray(ownBoards[boardId].activity)?.filter(
                            (incident) =>
                              incident.acknowledged === false &&
                              incident.mentionedUser === currentUser.email
                          ).length
                        }
                        color="error"
                      >
                        <CelebrationIcon />
                      </Badge>
                    )}
                  </div>
                </div>
              </div>
            ))}

          {/* Create Board Component */}
          <div
            className="board-item-big board-hover"
            style={{
              position: "relative",
              display: "flex",
              cursor: "pointer",
              color: "white",
              alignItems: "center",
              marginLeft: "20px",
              flexWrap: "wrap",
            }}
            onClick={() => setHandleOpenFromParent(!handleOpenFromParent)}
          >
            <div
              style={{
                width: "200px",
                height: "120px",
                backgroundColor: "darkgray",
                borderRadius: "2px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <CreateBoard
                handleOpenFromParent={handleOpenFromParent}
                setHandleOpenFromParent={setHandleOpenFromParent}
              />
            </div>
          </div>
        </div>

        {/* Collaborated Boards */}
        <Typography variant="h5" sx={{ marginY: "20px", fontWeight: "bold" }}>
          Collaborations
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(collaboratedBoards)
            .filter((boardId) => !isPinned(boardId))
            .map((boardId) => (
              <div
                key={boardId}
                onMouseEnter={() => setHoveredBoard(boardId)}
                onMouseLeave={() => setHoveredBoard(null)}
                onClick={() =>
                  handleCollaborationBoardClick(
                    boardId,
                    collaboratedBoards[boardId]?.owner
                  )
                }
              >
                <div
                  className="board-item-big board-hover"
                  style={{
                    position: "relative",
                    display: "flex",
                    cursor: "pointer",
                    color: "white",
                    alignItems: "center",
                    marginLeft: "20px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      height: "120px",
                      backgroundColor:
                        collaboratedBoards[boardId]?.background || "darkgray",
                      backgroundImage: collaboratedBoards[
                        boardId
                      ]?.background?.startsWith("http")
                        ? `url(${collaboratedBoards[boardId]?.background})`
                        : "none",
                      borderRadius: "2px",
                      backgroundSize: "cover",
                      padding: "10px",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgba(3, 3, 3, 0.6)",
                        width: "99%",
                        position: "absolute",
                        left: 0,
                        top: 8,
                        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.4)",
                        backdropFilter: "blur(1px)",
                        marginLeft: 1,
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ paddingX: "10px" }}>
                        {collaboratedBoards[boardId]?.title || "Untitled"}
                      </Typography>
                    </div>
                  </div>
                  {hoveredBoard === boardId && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePinBoard(boardId);
                        }}
                      >
                        <PushPinIcon />
                      </IconButton>
                      {convertToArray(
                        collaboratedBoards[boardId].activity
                      )?.filter(
                        (incident) =>
                          incident.acknowledged === false &&
                          incident.mentionedUser === currentUser.email
                      ).length > 0 && (
                        <Badge
                          badgeContent={
                            convertToArray(
                              collaboratedBoards[boardId].activity
                            )?.filter(
                              (incident) =>
                                incident.acknowledged === false &&
                                incident.mentionedUser === currentUser.email
                            ).length
                          }
                          color="error"
                        >
                          <CelebrationIcon />
                        </Badge>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Box>
  );
};

export default HomePage;
