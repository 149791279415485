import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Stack,
  Modal,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { ref, update } from "firebase/database";
import { getDB } from "../../../firebase/firebase";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImageIcon from "@mui/icons-material/Image";
import { useSettings } from "../../../Contexts/SettingsContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useThemeContext } from "../../../Contexts/ThemeDetailsContext";
import DownloadIcon from "@mui/icons-material/Download";
const Images = ({
  images,
  setImages,
  boardId,
  columnId,
  taskId,
  boardOwner,
  logAudit,
  taskPath,
  handleFileUpload,
  setUploading,
  uploading,
}) => {
  const storage = getStorage();
  const { currentUser } = useAuth();
  const db = getDB();
  const fileInputRef = useRef(null);
  const { settings } = useSettings();
  const [openModal, setOpenModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const themeDetails = useThemeContext();

  function handleDeleteImage(index) {
    const image = images[index];
    deleteObject(storageRef(storage, `images/${taskPath}/${image.name}`));
    setImages((prev) => prev.filter((_, i) => i !== index));
    // Update database
    const updatedImages = images.filter((_, i) => i !== index);
    const taskRef = ref(db, `${taskPath}`);
    update(taskRef, { images: updatedImages });
  }

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.items) {
      Array.from(event.dataTransfer.items).forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          handleFileUpload(file);
        }
      });
    }
  };

  const openImageModal = (index) => {
    setCurrentImage(index);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const openDeleteModal = (index) => {
    setDeleteIndex(index);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeleteIndex(null);
  };

  const confirmDelete = () => {
    if (deleteIndex !== null) {
      handleDeleteImage(deleteIndex);
    }
    closeDeleteModal();
  };

  const handleDownloadImage = async (index, e) => {
    e.stopPropagation();
    try {
      const image = images[index];
      if (!image || !image.url) return;

      // Extract the path from the existing URL
      const urlPath = decodeURIComponent(image.url)
        .split("?")[0] // Remove query parameters
        .split("/o/")[1]; // Get everything after /o/ in the Firebase Storage URL

      // Create an XMLHttpRequest with responseType blob
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";

      // Get fresh download URL using the extracted path
      const imageRef = storageRef(storage, urlPath);
      const downloadURL = await getDownloadURL(imageRef);

      xhr.open("GET", downloadURL);

      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = image.name || `image-${index}.jpg`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      };

      xhr.onerror = function () {
        console.error("Error downloading image");
      };

      xhr.send();
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  useEffect(() => {
    console.log(images);
  }, [images]);

  return (
    <Box
      onDrop={handleFileDrop}
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onDragLeave={() => setIsDragging(false)}
      sx={{
        border: isDragging ? "2px dashed #A0AD39" : "2px transparent",
        borderRadius: "15px",
        padding: "6px",
        width: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <ImageIcon
            sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
          />
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            Images
          </Typography>
        </Stack>
        <IconButton
          onClick={handleIconClick}
          aria-label="upload"
          sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
        >
          <UploadFileIcon />
        </IconButton>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={(e) => handleFileUpload(e.target.files[0])}
        />
      </div>

      <div style={{ maxHeight: "350px", width: "540px" }}>
        {uploading && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <CircularProgress sx={{ color: "#A0AD39" }} />
          </Box>
        )}
        {images.length === 0 && !uploading && (
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            Drag images over to upload..
          </Typography>
        )}

        {!uploading && (
          <Carousel
            showThumbs={false}
            onClickItem={openImageModal}
            infiniteLoop={true}
            dynamicHeight={true}
            width="510px"
          >
            {images.map((image, index) => (
              <div
                key={index}
                style={{
                  position: "relative",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    openDeleteModal(index);
                  }}
                  style={{
                    position: "absolute",
                    top: 1,
                    left: 30,
                    zIndex: 99,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
                <IconButton
                  onClick={(e) => handleDownloadImage(index, e)}
                  style={{
                    position: "absolute",
                    bottom: 20,
                    right: 30,
                    zIndex: 99,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <DownloadIcon />
                </IconButton>

                <img
                  src={image.url}
                  alt={image.name}
                  style={{
                    cursor: "pointer",
                    width: "auto",
                    maxHeight: "350px",
                  }}
                  onClick={() => openImageModal(index)}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>

      <Modal
        open={openModal}
        onClose={closeModal}
        sx={{
          display: "flex",
          width: "100dvw",
          height: "100dvh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "60%" }}>
          <Carousel
            selectedItem={currentImage}
            showThumbs={false}
            dynamicHeight={true}
            infiniteLoop={true}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image.url}
                  alt={image.name}
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            ))}
          </Carousel>
        </Box>
      </Modal>

      <Modal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 2,
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Confirm Delete
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 4 }}>
            Are you sure you want to delete this image?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="default" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={confirmDelete}>
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default Images;
