import {
  Alert,
  Divider,
  IconButton,
  Slider,
  Snackbar,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  ColorButton,
  RealDivider,
  TeldioSlider,
  TeldioSwitch,
} from "../../MUI_Components/Components";
import axios from "axios";
import { useSettings } from "../../Contexts/SettingsContext";
import SettingsIcon from "@mui/icons-material/Settings";
import { ref, set } from "firebase/database";
import { useAuth } from "../../firebase/contexts/AuthContext";
import { getDB } from "../../firebase/firebase";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getAuth, getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { isTeldio } from "../../Helper/utils";

const DemoPage = () => {
  const [temp, setTemp] = useState(0);
  const [breach, setBreach] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const { settings } = useSettings();
  const [page, setPage] = useState(null);
  const [serverIP, setServerIP] = useState();
  const [clientID, setClientID] = useState();
  const [tempThingsName, setTempThingsName] = useState();
  const [doorThingsName, setDoorThingsName] = useState();
  const { currentUser } = useAuth();
  const [error, setError] = useState("");
  const [emergencyIdent, setEmergencyIdent] = useState("");
  const [emergencySendError, setEmergencySendError] = useState("");
  const [cooldown, setCooldown] = useState(0);
  const [role, setRole] = useState(null);
  const [isTeldioRole, setIsTeldioRole] = useState(false);

  // New state for internal endpoints
  const [internalEndpoints, setInternalEndpoints] = useState({});
  const [selectedEndpoint, setSelectedEndpoint] = useState("External");
  const wsRef = useRef(null);

  useEffect(() => {
    if (!settings?.demo) {
      setPage(1);
    } else {
      setPage(0);
      setServerIP(settings.demo.serverIP);
      setClientID(settings.demo.clientID);
      setTempThingsName(settings.demo.tempName);
      setDoorThingsName(settings.demo.doorThingsName);
    }
  }, [settings]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserRole(user);
      } else {
        setRole("No user signed in");
        setIsTeldioRole(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Initialize WebSocket connection if user has Teldio role
  useEffect(() => {
    if (isTeldioRole) {
      // Initialize WebSocket connection
      wsRef.current = new WebSocket(
        "wss://caledonia.teldio.com/app/ws/trufleet-demo"
      );

      wsRef.current.onopen = () => {
        console.log("WebSocket connection established");
      };

      wsRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          // Check if the message contains endpoints
          if (data.endpoints) {
            console.log("Received endpoints via WebSocket:", data.endpoints);
            setInternalEndpoints(data.endpoints);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      wsRef.current.onclose = () => {
        console.log("WebSocket connection closed");
      };

      wsRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      return () => {
        if (wsRef.current) {
          wsRef.current.close();
        }
      };
    }
  }, [isTeldioRole]);

  const fetchUserRole = async (user) => {
    const tokenResult = await getIdTokenResult(user);
    setRole(tokenResult?.claims?.role || "");
    setIsTeldioRole(isTeldio(tokenResult?.claims?.role));
  };

  const db = getDB();

  function saveSettings() {
    const settingsRef = ref(db, `/uid/${currentUser.uid}/settings/demo`);

    if (!serverIP || !tempThingsName || !doorThingsName || !clientID) {
      setError("All fields must have values");
      return;
    }
    setError("");
    setOpenSuccess(true);

    const settingsToSave = {
      serverIP: serverIP,
      clientID: clientID,
      tempName: tempThingsName,
      doorThingsName: doorThingsName,
    };

    console.log(settingsToSave);

    set(settingsRef, settingsToSave)
      .then(() => {
        console.log("Update successful");
      })
      .catch((error) => {
        console.error("Failed to set settings:", error);
      });
  }

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const [openFail, setOpenFail] = useState(false);

  const handleCloseFail = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFail(false);
  };

  const sendWebSocketEvent = (eventType, data) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const message = {
        eventType,
        endpoint: selectedEndpoint,
        ...data,
      };

      wsRef.current.send(JSON.stringify(message));
      setOpenSuccess(true);
      return true;
    } else {
      console.error("WebSocket is not connected");
      setOpenFail(true);
      return false;
    }
  };

  async function raiseEmergency() {
    if (!emergencyIdent) {
      setEmergencySendError("You must provide a Radio ID");
      return;
    }
    if (cooldown > 0) return; // Prevent sending if cooldown is active

    setCooldown(30); // Start cooldown immediately
    setEmergencySendError("");

    // Use WebSocket for internal endpoints
    if (selectedEndpoint !== "External" && isTeldioRole) {
      const success = sendWebSocketEvent("raiseEmergency", {
        clientId: settings.demo.clientID,
        radioId: emergencyIdent,
        ip: internalEndpoints[selectedEndpoint],
        port: 7521,
      });

      if (!success) {
        setCooldown(0); // Cancel cooldown on failure
      }

      return;
    }

    // Use Firebase function for external endpoint
    try {
      const res = await axios.post(
        "https://us-central1-teldiotools.cloudfunctions.net/raiseEmergency",
        {
          clientId: settings.demo.clientID,
          radioId: emergencyIdent,
          ip: settings.demo.serverIP,
          port: 7521,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res.data);
      if (res.data.includes("Emergency raised successfully")) {
        setOpenSuccess(true);
      } else {
        setOpenFail(true);
        setCooldown(0); // Cancel cooldown on failure
      }
    } catch (err) {
      console.error(err);
      setOpenFail(true);
      console.log("Error Raising Emergency");
      setCooldown(0); // Cancel cooldown on failure
    }
  }

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [cooldown]);

  async function modifyBreach() {
    let newBreach = !breach;
    setBreach(newBreach);

    // Use WebSocket for internal endpoints
    if (selectedEndpoint !== "External" && isTeldioRole) {
      sendWebSocketEvent("sendXml", {
        deviceName: settings.demo.doorThingsName,
        colour: newBreach ? "Red" : "Green",
        icon: newBreach ? "fa-unlock" : "fa-lock",
        eventName: "Door Breach Event",
        notificationText: newBreach
          ? "Door has been breached"
          : "Door has been locked",
        emergency: newBreach ? "true" : "false",
        ip: internalEndpoints[selectedEndpoint],
        port: 7521,
      });

      return;
    }

    // Use Firebase function for external endpoint
    try {
      const res = await axios.post(
        "https://us-central1-teldiotools.cloudfunctions.net/sendXml",
        {
          deviceName: settings.demo.doorThingsName,
          colour: newBreach ? "Red" : "Green",
          icon: newBreach ? "fa-unlock" : "fa-lock",
          eventName: "Door Breach Event",
          notificationText: newBreach
            ? "Door has been breached"
            : "Door has been locked",
          emergency: newBreach ? "true" : "false",
          ip: settings.demo.serverIP,
          port: 7521,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res.data);
      if (res.data.includes("Success")) {
        setOpenSuccess(true);
      } else {
        setOpenFail(true);
      }
    } catch (err) {
      console.error(err);
      setOpenFail(true);
      console.log("Error sending XML");
    }
  }

  async function modifyTemp(val) {
    const oldTemp = temp;
    setTemp(val);
    if ((oldTemp < 70 && val >= 70) || (oldTemp >= 70 && val < 70)) {
      // Use WebSocket for internal endpoints
      if (selectedEndpoint !== "External" && isTeldioRole) {
        sendWebSocketEvent("sendXml", {
          deviceName: settings.demo.tempName,
          colour: val >= 70 ? "Red" : "Blue",
          icon: "fa-fire",
          eventName: "Temperature Event",
          notificationText:
            val >= 70
              ? "Temperature above threshold"
              : "Temperature below threshold",
          emergency: val >= 70 ? "true" : "false",
          ip: internalEndpoints[selectedEndpoint],
          port: 7521,
        });

        return;
      }

      // Use Firebase function for external endpoint
      try {
        const res = await axios.post(
          "https://us-central1-teldiotools.cloudfunctions.net/sendXml",
          {
            deviceName: settings.demo.tempName,
            colour: val >= 70 ? "Red" : "Blue",
            icon: "fa-fire",
            eventName: "Temperature Event",
            notificationText:
              val >= 70
                ? "Temperature above threshold"
                : "Temperature below threshold",
            emergency: val >= 70 ? "true" : "false",
            ip: settings.demo.serverIP,
            port: 7521,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(res.data);
        if (res.data.includes("Success")) {
          setOpenSuccess(true);
        } else {
          setOpenFail(true);
        }
      } catch (err) {
        console.error(err);
        setOpenFail(true);
        console.log("Error sending XML");
      }
    }
  }

  const marks = [
    {
      value: 0,
      label: "0°F",
    },
    {
      value: 70,
      label: "70°F",
    },
    {
      value: 100,
      label: "100°F",
    },
  ];

  return (
    <div className="container">
      <div style={{ position: "absolute", right: "10px", marginTop: "10px" }}>
        {page === 0 && (
          <IconButton
            aria-label="settings"
            onClick={() => {
              setPage(1);
            }}
          >
            <SettingsIcon />
          </IconButton>
        )}
        {page === 1 && settings?.demo && (
          <IconButton
            aria-label="back"
            onClick={() => {
              setPage(0);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      </div>
      {page === 0 && (
        <div
          style={{
            backgroundColor: "white",
            marginTop: "50px",
            width: "clamp(400px, 60%, 400px)",
            borderRadius: "30px",
            boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
            padding: "20px 20px 40px 20px",
            position: "relative",
          }}
        >
          <div className="bannerContainer">
            <img
              src={"assets/teldio_logo.png"}
              alt="Teldio Logo"
              className="logo"
            />
          </div>
          <div style={{ marginTop: "50px", width: "90%", marginLeft: "5%" }}>
            <Typography variant="h5" sx={{ marginBottom: "5px" }}>
              Raise Emergency
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ marginTop: "10px", color: "red" }}
              >
                {emergencySendError}
              </Typography>
              <TextField
                fullWidth
                label="Radio ID"
                sx={{ marginTop: "10px" }}
                value={emergencyIdent}
                onChange={(e) => setEmergencyIdent(e.target.value)}
              />
              <div style={{ display: "flex", justifyContent: "right" }}>
                <ColorButton onClick={raiseEmergency} disabled={cooldown > 0}>
                  {cooldown > 0 ? `Cooldown ${cooldown}s` : "Send"}
                </ColorButton>
              </div>
            </div>
          </div>
          <div style={{ width: "90%", marginLeft: "5%" }}>
            <Typography variant="h5">Temperature Sensor</Typography>
            <TeldioSlider
              aria-label="Custom marks"
              value={temp}
              sx={{ marginTop: "50px" }}
              step={10}
              valueLabelDisplay="auto"
              marks={marks}
              onChange={(e) => modifyTemp(e.target.value)}
            />
          </div>
          <Typography
            variant="h5"
            sx={{
              textAlign: "right",
              marginTop: "15px",
              color: temp >= 69 ? "red" : "black",
            }}
          >
            Temp: {temp}°F
          </Typography>

          <div style={{ marginTop: "5px", width: "90%", marginLeft: "5%" }}>
            <Typography variant="h5" sx={{ marginBottom: "30px" }}>
              Door Breach
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <TeldioSwitch checked={breach} onChange={() => modifyBreach()} />
            </div>
          </div>

          {/* Endpoint selection chips */}
          {isTeldioRole && (
            <div
              style={{
                position: "absolute",
                bottom: "-40px",
                left: "0",
                right: "0",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                flexWrap: "wrap",
                padding: "0 10px",
              }}
            >
              <Chip
                label="Hosted"
                color={selectedEndpoint === "External" ? "primary" : "default"}
                onClick={() => setSelectedEndpoint("External")}
                variant={
                  selectedEndpoint === "External" ? "filled" : "outlined"
                }
              />
              {Object.keys(internalEndpoints).map((endpoint) => (
                <Chip
                  key={endpoint}
                  label={endpoint}
                  color={selectedEndpoint === endpoint ? "primary" : "default"}
                  onClick={() => setSelectedEndpoint(endpoint)}
                  variant={
                    selectedEndpoint === endpoint ? "filled" : "outlined"
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}

      {page === 1 && (
        <div
          style={{
            backgroundColor: "white",
            marginTop: "50px",
            width: "clamp(400px, 60%, 400px)",
            borderRadius: "30px",
            boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
            padding: "20px 20px 40px 20px",
          }}
        >
          <div className="bannerContainer">
            <img
              src={"assets/teldio_logo.png"}
              alt="Teldio Logo"
              className="logo"
            />
          </div>
          <div style={{ marginTop: "50px", width: "90%", marginLeft: "5%" }}>
            <Typography
              variant="subtitle2"
              sx={{ marginTop: "10px", color: "red" }}
            >
              {error}
            </Typography>
            <Typography variant="h5" sx={{ marginTop: "5px" }}>
              Trufleet Plus
            </Typography>
            <TextField
              fullWidth
              label="Server IP"
              sx={{ marginTop: "10px" }}
              value={serverIP}
              onChange={(e) => setServerIP(e.target.value)}
            />
            <TextField
              fullWidth
              label="Client ID"
              sx={{ marginTop: "15px" }}
              value={clientID}
              onChange={(e) => setClientID(e.target.value)}
            />
            <RealDivider />

            <Typography variant="h5" sx={{ marginTop: "10px" }}>
              Temperature Sensor
            </Typography>
            <TextField
              fullWidth
              label="Things Name"
              sx={{ marginTop: "10px" }}
              value={tempThingsName}
              onChange={(e) => setTempThingsName(e.target.value)}
            />
          </div>
          <RealDivider />
          <div style={{ marginTop: "10px", width: "90%", marginLeft: "5%" }}>
            <Typography variant="h5">Door Breach</Typography>

            <TextField
              fullWidth
              label="Things Name"
              sx={{ marginTop: "10px" }}
              value={doorThingsName}
              onChange={(e) => setDoorThingsName(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <ColorButton onClick={saveSettings}>Save</ColorButton>
          </div>
        </div>
      )}
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFail}
        autoHideDuration={6000}
        onClose={handleCloseFail}
      >
        <Alert
          onClose={handleCloseFail}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Failure Sending Event
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DemoPage;
